import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Schemajeux } from "../constant/Position"
import { retry, catchError } from 'rxjs/operators'
import { ENV_PROD } from '../../environments/config-env';

@Injectable({
  providedIn: 'root'
})
export class SchemajeuxService {

  public apiUrl = ENV_PROD.urlApi;

  constructor(
    private httpClient: HttpClient
  ) { }

  /**
   * getColis
   */

  public ajouterSchemajeux( body): Observable<Schemajeux> {
    return this.httpClient.post<Schemajeux>(
      this.apiUrl + 'schemajeux/add',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }


  public updateSchemajeux( body): Observable<Schemajeux> {
    return this.httpClient.post<Schemajeux>(
      this.apiUrl + 'schemajeux/update',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }


  public deleteSchemajeux(id): Observable<Schemajeux> {
    return this.httpClient.get<Schemajeux>(
      this.apiUrl + 'schemajeux/delete/' + id,
    ).pipe(retry(1), catchError(this.handleError))
  }

  public getSchemajeux(): Observable<Schemajeux> {
    return this.httpClient.get<Schemajeux>(
      this.apiUrl + 'schemajeux/getall',
    ).pipe(retry(1), catchError(this.handleError))
  }



  //-------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------


  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
}

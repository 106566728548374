<div class="container">
    <button style="margin-bottom: 15px;" class="btn btn-success" (click)="openActionDiplome('add')">
        <span><i class="fas fa-plus-circle"></i> Nouveau</span>
    </button>

    <div class="card">
        <div class="card-body">
            <div class="row">
                <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTrigers">
                    <thead>
                        <tr>
                            <th>Diplôme</th>
                            <th>Description</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listDiplome">
                            <td>{{item?.name}}</td>
                            <td>{{item?.description}}</td>
                            <td>
                                <button class="btn-xs btn-success" (click)="openActionDiplome(item)">
                                    <i class="fas fa-pencil-alt"></i>
                                </button>
                                <button class="btn-xs btn-danger ml-1" (click)="emitDataForDelete(item.id)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #actionDiplome>
    <div class="modal-header">
        <h5 *ngIf="checkAction == 'add'">Ajout diplôme</h5>
        <h5 *ngIf="checkAction == 'update'">Modification Diplôme</h5>
    </div>

    <div class="modal-body">
        <div class="form-group">
            <label>Diplôme</label>
            <input class="form-control" type="text" name="diplome" [(ngModel)]="diplome">
        </div>
        <div class="form-group">
            <label>Description</label>
            <input class="form-control" type="text" name="description" [(ngModel)]="description">
        </div>
    </div>

    <div class="modal-footer">
        <input *ngIf="checkAction == 'add'" type="button" class="btn btn-outline-dark" value="Ajouter"
            (click)="createDiplome()">
        <input *ngIf="checkAction == 'update'" type="button" class="btn btn-outline-dark" value="Modifier"
            (click)="updateDiplome()">
        <input type="button" class="btn btn-outline-dark" value="Annuler" (click)="closeModal()">
    </div>
</ng-template>

<ng-template #beforeDelete>
    <div class="modal-header">
        <h5>Suppression de diplôme</h5>
    </div>

    <div class="modal-body">
        Etes-vous sûr de vouloir supprimer ce diplômes ?
    </div>

    <div class="modal-footer">
        <input type="button" class="btn btn-outline-dark" value="Supprimer" (click)="deleteDiplome()">
        <input type="button" class="btn btn-outline-dark" value="Annuler" (click)="closeModal()">
    </div>
</ng-template>

import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Position, Joueur , Entraineur} from "../constant/Position"
import { retry, catchError } from 'rxjs/operators'
import { ENV_PROD } from '../../environments/config-env';

@Injectable({
  providedIn: 'root'
})
export class EntraineurService {

  public apiUrl = ENV_PROD.urlApi;

  constructor(
    private httpClient: HttpClient
  ) { }

  /**
   * getColis
   */


  public getEntraineurs(): Observable<Entraineur> {
    return this.httpClient.get<Entraineur>(
      this.apiUrl + 'entraineur/getallentraineuradmin',
    ).pipe(retry(1), catchError(this.handleError))
  }



  public getEntraineursBetweenTwoDates(token, body): Observable<Entraineur> {
    return this.httpClient.post<Entraineur>(
      this.apiUrl + 'entraineur/entraineursbetween2date',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }


  public delete(token, id): Observable<Joueur> {
    return this.httpClient.get<Joueur>(
      this.apiUrl + 'users/delete/' + id ,
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }

  public activedesactiveEntraineurs(token, id, trueorfalse): Observable<Entraineur> {
    return this.httpClient.get<Joueur>(
      this.apiUrl + 'entraineur/activedesactive/' + id + '/' + trueorfalse,
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }
  //-------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------


  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
}

import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  OnChanges,
  OnDestroy,
  ElementRef,
  LOCALE_ID,
  Inject,
  Output
} from '@angular/core';
import { JoueurService } from 'src/app/services/joueur.service';
import { SchemajeuxService } from 'src/app/services/schemajeux.service';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import {NgbAlert, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import {NgxSpinnerService} from "ngx-spinner";
import {DatePipe, formatDate} from "@angular/common";
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import * as moment from 'moment';
import {ENV_PROD} from "../../../environments/config-env";
import { Router } from '@angular/router';
//-------------------------------------------
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {catchError, retry} from "rxjs/operators";
import {Admin} from "../../constant/Position";
import {ClubService} from "../../services/club.service";
import {AdminService} from "../../services/admin.service";

@Component({
  selector: 'app-ajouterentraineur',
  templateUrl: './ajouterentraineur.component.html',
  styleUrls: ['./ajouterentraineur.component.css']
})

export class ajouterentraineurComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;
  @Output() navigateTo: EventEmitter<any> = new EventEmitter();

  fileUploadForm: FormGroup;
  fileInputLabel: string;
  public dtTigger = new Subject();
  public avatar: any = "";
  public listeSchemajeux: any = [];
  public listeDiplome: any = [];
  public hommefemme: string = "";
  public categoriesexe: string = "";
  public first_name: string = "";
  public last_name: string = "";
  public username: string = "";
  public datenaissance: string = "";
  public taille: string = "";
  public poids: string = "";
  public telephone: string = "";
  public email: string = "";
  public nationalite1: string = "";
  public nationalite2: string = "";
  public region: string = "";
  public departement: string = "";
  public schemaJeuxFavoris: string = "";
  public disponibilite: string = "";
  public password: string = "";
  public confirmpassword: string = "";
  public isnouveauclub: any = null;
  public club: string = null;
  public newClub: string = "";
  public emailClub: string = "";
  public numeroAffiliation: string = "";
  public diplome: string = "";
  public listeClub: any = [];
  public index1: any = 0;
  public index2: any = 150;

  constructor(
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private joueurService: JoueurService,
    private schemajeuxService: SchemajeuxService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private router: Router,
    private clubService: ClubService,
    private adminService: AdminService,
    @Inject(LOCALE_ID) private locale: string

  ) {

  }


  ngOnInit(): void {
   this.load()
  }


  ngOnDestroy(): void {
  this.dtTigger.unsubscribe()
  }


  ngOnChanges(): void {
    this.load()
  }

  load(){
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);

    this.clubService.getListeclub()
      .subscribe(async (res: any) => {
        this.listeClub = res.data;
        this.spinner.hide();
      });

    this.schemajeuxService.getSchemajeux()
      .subscribe(async (res: any) => {
        if (res.error === "Unauthorized" && res.message === 'Authentication failed (token) decoded sub.') {
          localStorage.setItem('userToken', "");
          this.router.navigateByUrl('');
        } else {
          this.listeSchemajeux = res.data;
          if(this.listeSchemajeux.length === 0){
            this.spinner.hide();
            if (window.confirm("Veuillez ajouter d'abord du schéma de jeux avant d'ajouter un entraineur"))
            {
              this.navigateTo.emit({page:"schemajeux", titlePage:"Schéma de jeux"})
            }
            else
            {
              // They clicked no
            }
          }
          this.dtTigger.next();
          this.spinner.hide()
        }
      });

    this.joueurService.getDiplome()
      .subscribe(async (res: any) => {
        if (res.error === "Unauthorized" && res.message === 'Authentication failed (token) decoded sub.') {
          localStorage.setItem('userToken', "");
          this.router.navigateByUrl('');
        } else {
          this.listeDiplome = res.data;
          if(this.listeDiplome.length === 0){
            this.spinner.hide();
            if (window.confirm("Veuillez ajouter d'abord du diplôme avant d'ajouter un entraineur"))
            {
              this.navigateTo.emit({page:"diplome", titlePage:"Diplômes"})
            }
            else
            {
              // They clicked no
            }
          }
          this.dtTigger.next();
          this.spinner.hide()
        }
      });

    this.fileUploadForm = this.formBuilder.group({
      uploadedImage: [''],
    });
  }


  onFileSelect(event) {
    const file = event.target.files[0];
    this.fileInputLabel = file.name;
    this.fileUploadForm.get('uploadedImage').setValue(file);
  }

  public async changeClient(item: any) {
    this.schemaJeuxFavoris = item;
  }
  public async changeClub(item: any) {
    this.club = item;
  }
  public async changeDiplome(item: any) {
    this.diplome = item;
  }




  async ajoutentraineur(){

    if(this.hommefemme === ""){
      alert("Veuillez choisir le genre de l'entraineur.");
      return;
    }

    if(this.first_name === ""){
      alert("Veuillez inserer le nom de l'entraineur.");
      return;
    }
    if(this.last_name === ""){
      alert("Veuillez inserer le prénoms de l'entraineur.");
      return;
    }
    if(this.username === ""){
      alert("Veuillez inserer le nom d'utilisateur de l'entraineur.");
      return;
    }
    if(this.datenaissance === ""){
      alert("Veuillez inserer la date de naissance de l'entraineur."); //2021-07-12
      return;
    }

    if(this.taille === ""){
      alert("Veuillez insérer la taille de l'entraineur.");
      return;
    }
    if(this.poids === ""){
      alert("Veuillez insérer le poids de l'entraineur.");
      return;
    }
    // if(this.schemajeux === null){
    //   alert("Veuillez choisir au moins une position.");
    //   return;
    // }
    if(this.telephone === ""){
      alert("Veuillez insérer le numéro de téléphone de l'entraineur.");
      return;
    }

    if(this.telephone !== ""  && !this.adminService.validatePhoneNumber(this.telephone.trim())){
      alert("Veuillez insérer un numéro de téléphone valide.");
      return;
    }
    if(this.email === ""){
      alert("Veuillez insérer l'email de l'entraineur.");
      return;
    }
    if(this.email !== "" && !this.adminService.validateEMail(this.email)){
      alert("Veuillez insérer un adresse e-mail valide.");
      return;
    }
    if(this.nationalite1 === ""){
      alert("Veuillez insérer la nationalité de l'entraineur.");
      return;
    }
    if(this.region === ""){
      alert("Veuillez insérer la région où habite l'entraineur.");
      return;
    }
    if(this.departement === ""){
      alert("Veuillez insérer le département où habite l'entraineur.");
      return;
    }
    if(this.disponibilite === ""){
      alert("Veuillez choisir la disponibilité de l'entraineur.");
      return;
    }
    if(this.disponibilite !== "LIBRE"){
      if(this.isnouveauclub == null && this.club === null && this.newClub === ""){
        alert("Veuillez choisir le club pour le membre.");
        return;
      }
      if(this.isnouveauclub == 'true' &&  this.newClub === ""){
        alert("Veuillez inserer le nom du nouveau club.");
        return;
      }
      if( this.isnouveauclub === 'false' && this.club === null){
        alert("Veuillez choisir le club.");
        return;
      }
      if(this.isnouveauclub == 'true' && this.emailClub === ""){
        alert("Veuillez insérer l'email du club.");
        return;
      }
      if(this.emailClub !== "" && !this.adminService.validateEMail(this.emailClub)){
        alert("Veuillez insérer un adresse e-mail valide.");
        return;
      }
      if(this.isnouveauclub == 'true' && this.numeroAffiliation === ""){
        alert("Veuillez insérer le numéro d'affiliation du club.");
        return;
      }
    }

    if(this.categoriesexe === ""){
      alert("Veuillez choisir la catégorie desirée.");
      return;
    }

    if(this.password === ""){
      alert("Veuillez inserer le mot de passe pour l'entraineur.");
      return;
    }
    if(this.password !== this.confirmpassword){
      alert("Le mot de passe et la confirmation du mot de passe n'est pas identique.");
      return;
    }

    let parent = {};
    const data = {
      sexe:this.hommefemme,
      categoriesexe:this.categoriesexe,
      first_name:this.first_name.trim(),
      last_name:this.last_name.trim(),
      username:this.username.trim(),
      dateNaissance: this.datenaissance,
      //------------
      taille:this.taille,
      poids:this.poids,
      phone: this.telephone.trim(),
      email:this.email.trim().toLowerCase(),
      nationalite1: this.nationalite1.trim(),
      region:this.region.trim(),
      departement:this.departement.trim(),
      role:"ENTRAINEUR",
      disponibilite:this.disponibilite,
      password:this.password,
      checkCondition:true

    };
    if(this.schemaJeuxFavoris !== ""){
      let schemaJeuxFavoris = [];
      const schemaJeuxFavoris_split = this.schemaJeuxFavoris;
      for(let i = 0; i<this.schemaJeuxFavoris.length; i++){
        for(let j = 0; j<this.listeSchemajeux.length; j++){
          if(schemaJeuxFavoris_split[i] === this.listeSchemajeux[j].id){
            await schemaJeuxFavoris.push(this.listeSchemajeux[j]);
            break;
        }}
      }
      parent['schemaJeuxFavoris'] = await JSON.stringify(schemaJeuxFavoris);
    }

    if(this.diplome !== ""){
      let diplome = [];
      const diplome_split = this.diplome;
      for(let i = 0; i<this.diplome.length; i++){
        for(let j = 0; j<this.listeDiplome.length; j++){
          if(diplome_split[i] === this.listeDiplome[j].id){
            await diplome.push(this.listeDiplome[j]);
            break;
          }}
      }
      parent['diplome'] = await JSON.stringify(diplome);
    }

    if( this.nationalite2 !== ""){
      data['nationalite2'] = this.nationalite2;
    }
    if(this.isnouveauclub === 'true'){
      parent["club"] ={ id:0 };
      parent["newClub"] = this.newClub;
      parent["emailClub"] = this.emailClub;
      parent["numeroAffiliation"] = this.numeroAffiliation;
    }else {
      parent["club"] = this.club;
    }

    this.spinner.show();
    setTimeout(()=>{
      this.spinner.hide();
    },12000);
    const formData = new FormData();
    let headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin' : '*',
    });
    let options = { headers: headers };
    formData.append('data', this.fileUploadForm.get('uploadedImage').value);
    this.uploadFileInput.nativeElement.value = '';
    if (this.fileUploadForm.get('uploadedImage').value){
      this.httpClient.post<any>(ENV_PROD.urlApi + 'upload-image',
        formData
        // , options
      ).subscribe(
        (response) => {
          if (response.status === 200) {
            this.avatar = response.urlfile;
            data['avatar'] = response.nameFile;
            parent['user'] = data;
            this.fileInputLabel = undefined;
            this.httpClient.post<any>(ENV_PROD.urlApi + 'inscriptionjoueur', parent).subscribe(
            // this.httpClient.post<any>(environment.api + 'inscription', parent).subscribe(
              (response1) => {
                this.spinner.hide();
                if (response1.status === 200){
                  alert(ENV_PROD.nom_application + " \n " + response1.message);
                }else if (response1.status === 404 || response1.status === 300 || response1.status === 301){
                  alert(ENV_PROD.nom_application + " \n " +  response1.message);
                }})
          }else{
            alert(ENV_PROD.nom_application + " \n " + "L'image n'a pas pu être envoyée.");
          }
        })
    }else{
      parent['user'] = data;
      this.fileInputLabel = undefined;
      this.httpClient.post<any>(ENV_PROD.urlApi + 'inscriptionjoueur', parent).subscribe(
        (response1) => {
          this.spinner.hide();
          if (response1.status === 200){
            alert(ENV_PROD.nom_application + " \n " + response1.message);
          }else if (response1.status === 404 || response1.status === 300 || response1.status === 301){
            alert(ENV_PROD.nom_application + " \n " +  response1.message);
          }})
    }
  }

  public filterByIndex(index1, index2) {
    if(parseInt(index2) < parseInt(index1)){
      alert("Le deuxième champ ne pourrait être pas inférieur au premier champ.");
      return;
    }
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},12000);
    if(index1 !== "" && index2 !== ""){
      this.clubService.getClubbyIndex(index1, index2)
        .subscribe((res: any) => {
          this.listeClub = res.data;
          this.spinner.hide()
        })
    }
  }

}

<div class="container">
  <div class="card login-card">
    <div class="row no-gutters">
      <div class="col-md-5">
        <img src="../../../../assets/images/logo-mymatch-fond.png" alt="login" class="login-card-img">
      </div>
      <div class="col-md-7">
        <div class="card-body">
          <p class="login-card-description">Connectez-vous</p>
          <form class="validate-form">
            <div class="wrap-input100 validate-input" data-validate="username is required">
              <input class="input100" type="text" name="username" [(ngModel)]="username"
                     placeholder="Login">
              <span class="focus-input100"></span>
              <span class="label-input100">
                      <i class="fas fa-user"
                         style="margin-left: -2px;font-size: medium;margin-right: 15px; float: right; margin-top: -10px"></i>
                  </span>
            </div>

              <div class="wrap-input100 validate-input" data-validate="Password is required">
                  <input class="input100" type="password" name="password" [(ngModel)]="password"
                      placeholder="Mot de passe">
                  <span class="focus-input100"></span>
                  <span class="label-input100">
                      <i class="fas fa-key"
                          style="margin-left: -2px;font-size: medium;margin-right: 15px; float: right; margin-top: -10px"></i>
                  </span>
              </div>
            <div class="help-block"><span class="error">{{help_block}}</span></div>
            <input name="login" id="login" required class="btn btn-block login-btn mb-4" style="margin-top: 40px;" (click)="login()" type="button" value="Connexion">
          </form>

          <a href="#!" (click)="emitDataEmail()" class="forgot-password-link">Récuperer le mot de passe?</a>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #actionEmail>
  <div class="modal-header" style="padding-left: 15px; padding-right: 15px;">
    <h4 class="modal-title" style="margin-top: 12px;margin-bottom: 12px;">Récupération de mot de passe</h4>
    <div  class="close" aria-label="Close" (click)="resetField()">
      <span aria-hidden="false">&times;</span>
    </div>
  </div>
  <div class="modal-body" style="padding-left: 15px; padding-right: 15px;">
    <div class="form-group" style="margin-top: 20px;">
      <label>Email :</label>
      <input style="margin-top: 15px;" type="text" class="form-control" name="emails" [(ngModel)]="emailpassword">
    </div>
    <center style="margin-bottom: 12px;"><div *ngIf="messageemailinvalideOrDejaInBase !== ''">
      <h5 style="color: red;">{{messageemailinvalideOrDejaInBase}}</h5>
    </div></center>
  </div>
  <div class="modal-footer" style="margin: 15px;">
    <button type="button" class="" style="background-color: #6c950e; color: white; margin-top: 12px; padding: 15px; border-radius: 6px;" (click)="envoieMailPassword()"> <i class="fa fa-check-circle" aria-hidden="true"></i> Récuperer</button>
  </div>
</ng-template>


<div class="card-head">
  <button class="btn btn-success btn-sm pull-right" (click)="openModal(actionadmin)">
    <i class="fa fa-plus" aria-hidden="true"></i> Nouveau
  </button>
</div>

<div class="card-body">
  <div class="row">
    <!-- <table class="table"> -->
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
      <thead>
      <tr>
        <th>Nom</th>
        <th>E-mail</th>
        <!--                            <th>Mot de passe</th>-->
        <!--                            <th>Statut</th>-->
        <th>Adresse</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of listadmin" class="pointer-cl">
        <td (click)="emitDataadmin(item)">{{item.name}}</td>
        <td (click)="emitDataadmin(item)">{{item.email}}</td>
        <!--                            <td (click)="emitDataadmin(item)">{{decryptUsingAES256(item.password)}}</td>-->
        <!--                            <td (click)="emitDataadmin(item)">{{item.type == '1' ? 'Admin': 'admin'}}</td>-->
        <td (click)="emitDataadmin(item)">{{item.adress}}</td>
        <td>
          <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
            <i class="fas fa-trash"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


<ng-template #actionadmin>
  <div class="modal-header">

    <h4 class="modal-title" >Nouveau admin</h4>
    <div type="button" class="close" aria-label="Close" (click)="resetField()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Nom</label>
      <input type="text" class="form-control" name="adminName" [(ngModel)]="adminName">
    </div>

    <div class="form-group">
      <label>E-mail</label>
      <input type="text" class="form-control" name="adminName" [(ngModel)]="adminEmail">
    </div>
    <div class="form-group">
      <label>Adresse</label>
      <input type="text" class="form-control" name="adminAdress" [(ngModel)]="adminAdress">
    </div>

    <div class="form-group">
      <label>Mot de passe</label>
      <input type="password" class="form-control" name="adminPassword" [(ngModel)]="adminPassword">
    </div>
    <div class="form-group">
      <label>Confirmer le mot de passe</label>
      <input type="password" class="form-control" name="adminconfirmPassword" [(ngModel)]="adminconfirmPassword">
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="addadmin()"><i class="fa fa-plus-circle" aria-hidden="true"></i> Ajouter</button>
  </div>
</ng-template>


<ng-template #actionadmin1>
  <div class="modal-header">
    <h4 class="modal-title" >Modification admin</h4>

    <div type="button" class="close" aria-label="Close" (click)="resetField()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Nom</label>
      <input type="text" class="form-control" name="adminName" [(ngModel)]="adminName">
    </div>

    <div class="form-group">
      <label>E-mail</label>
      <input type="text" class="form-control" name="adminName" [(ngModel)]="adminEmail">
    </div>
    <div class="form-group">
      <label>Adresse</label>
      <input type="text" class="form-control" name="adminAdress" [(ngModel)]="adminAdress">
    </div>
    <div class="form-group">
      <div style="margin-top: 20px; margin-bottom: 10px;" >
        <input name="ismodifierpassword" id="ismodifierpassword" type="checkbox"  [(ngModel)]="isUpdateWithPassword"  (change)="ismodifierpassword($event)" >
        <label  style="margin-left: 10px; color: #1da1f2; font-weight: bold;" class="form-check-label" for="ismodifierpassword">
          Modifier aussi le mot de passe
        </label>
      </div>
      <label *ngIf="isUpdateWithPassword === false">Mot de passe</label>
      <label *ngIf="isUpdateWithPassword === true">Ancien mot de passe</label>
      <input type="password" class="form-control" name="adminPassword" [(ngModel)]="adminPassword">
    </div>

    <div class="form-group">
      <label *ngIf="isUpdateWithPassword === true">Nouveau mot de passe</label>
      <input *ngIf="isUpdateWithPassword === true" type="password" class="form-control" name="ancienPassword" [(ngModel)]="nouveauPassword">

    </div>
    <div class="form-group">
      <label *ngIf="isUpdateWithPassword === true">confirmer le nouveau mot de passe</label>
      <input *ngIf="isUpdateWithPassword === true" type="password" class="form-control" name="adminconfirmPassword" [(ngModel)]="adminconfirmPassword">
    </div>

  </div>
  <div class="modal-footer">
    <button  type="button" class="btn btn-info" (click)="updateadmin()"><i class="fa fa-check-circle" aria-hidden="true"></i> Enregister</button>
  </div>
</ng-template>

<ng-template #confirmDelete>
  <div class="card-body">
    Etes-vous sûr de vouloir supprimer?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="deleteadmin()">OUI</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
  </div>
</ng-template>


import {Component, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import { JoueurService } from 'src/app/services/joueur.service';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from "ngx-spinner";
import { Time } from "@angular/common";
import set = Reflect.set;
import {ENV_PROD} from "../../../environments/config-env";
// import { Socket } from 'ngx-socket-io';
import * as io  from "socket.io-client";
const SocketEndpoint = ENV_PROD.urlApi1;

import {AdminService} from "../../services/admin.service";

@Component({
  selector: 'app-signalerjoueur',
  templateUrl: './Signalerjoueur.component.html',
  styleUrls: ['./Signalerjoueur.component.css']
})
export class SignalerjoueurComponent implements OnInit {

  @ViewChild('actionVoyage') actionVoyage: any;
  @ViewChild('actionShowVoyage') actionShowVoyage: any;
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  @ViewChild('confirmDelete') confirmDelete: any;
  @Output() navigateToAfficherJoueur: EventEmitter<any> = new EventEmitter();

  @Output() navigateToModifyJoueur: EventEmitter<any> = new EventEmitter();


  public joueurId: any = null;
  public listJoueurs: any = [];
  public dtOptions: any = {};
  public dtTigger = new Subject();
  public emitData: EventEmitter<any> = new EventEmitter;
  public socket;

  constructor(
    private listerjoueurService: JoueurService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    // private socket: Socket,
    private adminService: AdminService,
  ) { }

  ngOnInit(): void {
    this.socket = io(SocketEndpoint);
    this.socket.on("notificationbadge_depuisserver", payload => {
      this.loadListejoueurs();
    });
    this.spinner.show();
    setTimeout(()=>{
      this.spinner.hide()
    },10000);
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100],
      "order": [[ 2, 'desc' ]]
    };

    this.listerjoueurService.getJoueurssignale()
      .subscribe((res: any) => {
        this.listJoueurs = res.data;
        this.dtTigger.next();
        this.spinner.hide()
      })
  }

  /**
   * loadListejoueurs
   */
  public loadListejoueurs() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.listerjoueurService.getJoueurssignale()
        .subscribe((res: any) => {
          this.listJoueurs = res.data;
          this.dtTigger.next();
          this.spinner.hide()
        })
    })
  }


  public async activedesactive(id, event) {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),6000);
    let token =  await localStorage.getItem('userToken');
    let adminId = localStorage.getItem('adminId');
    this.adminService.gettokenbyid(adminId)
      .subscribe(async (res: any) => {
        localStorage.setItem('userToken', res.data);
        token = res.data;
      });
    this.listerjoueurService.activedesactiveJoueurssignale(token, id, event.target.checked)
      .subscribe((res: any) => {
        this.listJoueurs = res.data;
        this.spinner.hide()
      })
  }


  /**
   * filterByDate
   */
  public filterByDate(item) {
    let dateSplte = item.split(',');
    this.getBy2date(dateSplte[0], dateSplte[1])
  }

  public async getBy2date(start, end) {
    this.spinner.show();
    setTimeout(()=>{
      this.spinner.hide()
    },10000);
    let token =  await localStorage.getItem('userToken');
    let adminId = localStorage.getItem('adminId');
    this.adminService.gettokenbyid(adminId)
      .subscribe(async (res: any) => {
        localStorage.setItem('userToken', res.data);
        token = res.data;
      });
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      let body = {
        startDate: start,
        endDate: end
      };
      this.listerjoueurService.getJoueurssignaleBetweenTwoDates(token, body)
        .subscribe((res: any) => {
          this.spinner.hide();
          this.listJoueurs = res.data;
          this.dtTigger.next()
        })
    })
  }


  /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.joueurId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }



  /**
   * delete
   */
  public async delete() {
    this.spinner.show();
    setTimeout(()=>{
      this.spinner.hide()
    },10000);
    let token =  await localStorage.getItem('userToken');
    let adminId = localStorage.getItem('adminId');
    this.adminService.gettokenbyid(adminId)
      .subscribe(async (res: any) => {
        localStorage.setItem('userToken', res.data);
        token = res.data;
      });
    this.listerjoueurService.delete(token, this.joueurId)
      .subscribe((res: any) => {
        this.loadListejoueurs();
        this.resetField();
        this.spinner.hide();
      })
  }

  public resetField() {
    this.ngbModal.dismissAll();
    this.joueurId = '';
  }
  /**
   * openModal
   */
  public openModal(modal) {
    this.ngbModal.open(modal)
  }

  // GEstion field data
  public closeModal() {
    this.ngbModal.dismissAll()
  }

  /**
   * emitData
   */
  public emitNavigateToModifyJoueur(userId) {
    localStorage.setItem('modificationId', userId);
    this.navigateToModifyJoueur.emit({page:"modifierjoueur", titlePage:"Modification joueur"})
  }


  public emitNavigateToAfficheJoueur(userId) {
    localStorage.setItem('modificationId', userId);
    this.navigateToModifyJoueur.emit({page:"afficherjoueur", titlePage:"Afficher joueur"})
  }


  public emitNavigateToShowImage(userId) {
    localStorage.setItem('modificationId', userId);
    this.navigateToModifyJoueur.emit({page:"showimagejoueur", titlePage:"Galleries photos"})
  }


  public emitNavigateToShowVideo(userId) {
    localStorage.setItem('modificationId', userId);
    this.navigateToModifyJoueur.emit({page:"showvideojoueur", titlePage:"Galleries vidéos"})
  }

}

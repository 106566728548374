import { Component, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { DatatableLanguage } from 'src/app/constant/french-datatable';
import { AlertService } from 'src/app/services/alert.service';
import { JoueurService } from 'src/app/services/joueur.service';

@Component({
  selector: 'app-diplome',
  templateUrl: './diplome.component.html',
  styleUrls: ['./diplome.component.css']
})
export class DiplomeComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('actionDiplome') actionDiplome: any;
  @ViewChild('beforeDelete') beforeDelete: any;

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings;
  public dtTrigers = new Subject();

  public listDiplome: any = [];

  public id: any;
  public diplome: any;
  public description: any;
  public checkAction: any;

  constructor(
    private service: JoueurService,
    private alert: AlertService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),6000);
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100],
     // "order": [[ 1, 'desc' ]]
    };
    this.service.getDiplome()
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.listDiplome = res.data;
          this.spinner.hide()
        } else {
          this.alert.reloadPage();
          this.spinner.hide()
        }
        this.dtTrigers.next()
      })
  }

  ngOnChanges(): void {
    this.loadDiplome()
  }

  ngOnDestroy(): void {
    this.dtTrigers.unsubscribe()
  }

  public loadDiplome() {
    this.closeModal();
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.service.getDiplome()
        .subscribe((res: any) => {
          if (res.status == 200) {
            this.listDiplome = res.data;
            this.spinner.hide()
          } else {
            this.alert.reloadPage();
            this.spinner.hide()
          }
          this.dtTrigers.next()
        })
    })
  }

  /**
   * create
   */
  public createDiplome() {
    const body = {
      name: this.diplome,
      description: this.description
    };
    if (this.diplome == "") {
      this.alert.emptyField('diplome')
    } else {
      this.service.ajouterDiplome( body)
        .subscribe((res: any) => {
          if (res.status == 200) {
            this.alert.addSuccess();
            this.loadDiplome()
          }
        })
    }
  }

  /**
   * update
   */
  public updateDiplome() {
    const parent = {
      id: this.id,
     body:{ name: this.diplome,
      description: this.description}
    };
    this.service.updateDiplome(parent)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.alert.updateSuccess();
          this.loadDiplome()
        } else {
          this.alert.error()
        }
      })
  }

  /**
   * delete
   */
  public deleteDiplome() {
    this.service.deleteDiplome(this.id)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.alert.deleteSuccess();
          this.loadDiplome()
        } else {
          this.alert.error()
        }
      })
  }

  /**
   * openAction
   */
  public openActionDiplome(item: any) {
    if (item == 'add') {
      this.checkAction = item;
      this.diplome = this.description = '';
      this.alert.openModal(this.actionDiplome)
    } else {
      this.checkAction = 'update';
      this.id = item?.id;
      this.diplome = item?.name;
      this.description = item?.description;
      this.alert.openModal(this.actionDiplome)
    }
  }

  /**
   * closeModal
   */
  public closeModal() {
    this.alert.closeModal()
  }

  /**
   * emitDataForDelete
   */
  public emitDataForDelete(diplomeId) {
    this.id = diplomeId;
    this.alert.openModal(this.beforeDelete)
  }
}

<script src="modifierclub.component.ts"></script>
<script src="../menu/menu.component.ts"></script>
<div class="card-body">
  <div class="row col-5">
    <form>
      <div class="form-group">
        <div *ngIf="logo != '' && logo != null">
          <img alt="logo" src="{{logo}}" style="width: 200px; height: 200px; padding:15px; borderRadius:10px; margin-bottom: 30px;"/>
        </div>
        <label for="logo" style="color:#259547; margin-bottom: 20px;">Importer le logo du club</label>
        <input type="file" accept="image/*" id="logo" class="form-control-file"  #UploadFileInput name="uploadedImage" placeholder="Photo" (change)="onFileSelect($event)" >
      </div>

      <div class="form-group" style="margin-top: 20px;">
        <div   class="form-group" style="margin-top: 15px;">
          <label for="newClub" style="color:#259547;">Nom du club</label>
          <input [(ngModel)]="newClub" type="text" class="form-control" name="newClub" id="newClub" placeholder="Nom du club*">
        </div>
      </div>

      <div class="form-group" style="margin-top: 15px;">
        <label for="emailClub" style="color:#259547;">E-mail du club</label>
        <input  [(ngModel)]="emailClub"   type="email" class="form-control"  name="emailClub" id="emailClub" aria-describedby="emailHelp" placeholder="E-mail du club*">
      </div>

      <div class="form-group" style="margin-top: 15px;">
        <label for="numeroAffiliation" style="color:#259547;">Numéro d'affiliation</label>
        <input [(ngModel)]="numeroAffiliation" type="text" class="form-control" name="numeroAffiliation" id="numeroAffiliation" placeholder="Numéro d'affiliation du club*">
      </div>

      <div class="form-group" style="margin-top: 15px;">
        <label for="pays" style="color:#259547;">Pays</label>
        <input [(ngModel)]="pays" type="text" class="form-control" name="pays" id="pays" placeholder="Pays*">
      </div>

      <div class="form-group" style="margin-top: 15px;">
        <label for="description" style="color:#259547;">Description</label>
        <input [(ngModel)]="description" type="text" class="form-control" name="description" id="description" placeholder="Description">
      </div>

      <button (click)="modifierclub()" style="margin-top: 25px; margin-bottom: 25px; padding-left: 20px; padding-right: 20px;" type="button" class="btn btn-success">Modifier le club</button>
    </form>
  </div>
</div>










<div class="container">
    <button style="margin-bottom: 15px;" class="btn btn-success" (click)="openActionSchemajeux('add')">
        <span><i class="fas fa-plus-circle"></i> Nouveau</span>
    </button>

    <div class="card">
        <div class="card-body">
            <div class="row">
                <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTrigers">
                    <thead>
                        <tr>
                            <th>Schéma de jeux</th>
                            <th>Description</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listSchemajeux">
                            <td>{{item?.name}}</td>
                            <td>{{item?.description}}</td>
                            <td>
                                <button class="btn-xs btn-success" (click)="openActionSchemajeux(item)">
                                    <i class="fas fa-pencil-alt"></i>
                                </button>
                                <button class="btn-xs btn-danger ml-1" (click)="emitDataForDelete(item.id)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #actionSchemajeux>
    <div class="modal-header">
        <h5 *ngIf="checkAction == 'add'">Ajout de schéma de jeux</h5>
        <h5 *ngIf="checkAction == 'update'">Modification de schéma de jeux</h5>
    </div>

    <div class="modal-body">
        <div class="form-group">
            <label>Schéma de jeux</label>
            <input class="form-control" type="text" name="schemajeux" [(ngModel)]="schemajeux">
        </div>
        <div class="form-group">
            <label>Description</label>
            <input class="form-control" type="text" name="description" [(ngModel)]="description">
        </div>
    </div>

    <div class="modal-footer">
        <input *ngIf="checkAction == 'add'" type="button" class="btn btn-outline-dark" value="Ajouter"
            (click)="createSchemajeux()">
        <input *ngIf="checkAction == 'update'" type="button" class="btn btn-outline-dark" value="Modifier"
            (click)="updateSchemajeux()">
        <input type="button" class="btn btn-outline-dark" value="Annuler" (click)="closeModal()">
    </div>
</ng-template>

<ng-template #beforeDelete>
    <div class="modal-header">
        <h5>Suppression de schéma de jeux</h5>
    </div>

    <div class="modal-body">
        Etes-vous sûr de vouloir supprimer ce schéma de jeux ?
    </div>

    <div class="modal-footer">
        <input type="button" class="btn btn-outline-dark" value="Supprimer" (click)="deleteSchemajeux()">
        <input type="button" class="btn btn-outline-dark" value="Annuler" (click)="closeModal()">
    </div>
</ng-template>

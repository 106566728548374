<div class="card-body">
<!--  <div class="row" style="margin-top: 12px; display: flex; flex-direction: row; align-items: center;">-->
<!--    <div style="font-size: medium">&nbsp;&nbsp;&nbsp; Afficher par index de : &nbsp;&nbsp;&nbsp;</div>-->
<!--    <div><input id="index1" type="number" value="0" class="form-control" name="debut" [(ngModel)]="index1"></div>-->
<!--    <div style="font-size: medium">&nbsp;&nbsp;&nbsp; à: &nbsp;&nbsp;&nbsp;</div>-->
<!--    <input id="index2" type="number" value="150" style="width: 195px" class="form-control" name="fin" [(ngModel)]="index2">-->
<!--    <button style="margin-left: 15px;" class="btn btn-success btn-sm pull-right" (click)="filterByIndex(index1, index2)">-->
<!--      <i class="fa fa-list" aria-hidden="true"></i> Afficher-->
<!--    </button>-->
<!--  </div>-->
  <div class="row" style="margin-top: 15px;">
    <div class="row" style="">
      <div class="col-3 offset-9" style="top:20px">
        <input id="indexsearch" type="text" placeholder="Rechercher" class="form-control"  (keyup)="rechercherclub($event)"  [(ngModel)]="search">
      </div>
    </div>
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
      <thead>
      <tr>
        <th>Logo</th>
        <th>Nom du club</th>
        <th>email</th>
        <th>pays</th>
        <th>Description</th>
        <th>Numéro d'affiliation</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of listClub" class="pointer-cl">
        <td><div *ngIf="item.logo != '' && item.logo != null">
          <img alt="logo" src="{{item.url_logo}}" style="width: 100px; height: 78px; padding:5px; borderRadius:3px; margin: 4px;"/>
        </div></td>
        <td>{{item.name !== null ? item.name : ''}}</td>
        <td>{{item?.email | lowercase}}</td>
        <td>{{item.pays !== null ? (item.pays  | titlecase ) : ''}}</td>
        <td>{{item.description !== null ? item.description : ''}}</td>
        <td>{{item.numeroAffiliation !== null ? item.numeroAffiliation : ''}}</td>
        <td style="display: flex; flex-direction: row">
          <button style="margin-right: 5px;" class="btn btn-sm btn-success"  (click)="emitNavigateToModifyClub(item.id)">
            <i class="fas fa-edit"></i>
          </button>
          <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
            <i class="fas fa-trash"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


<ng-template #confirmDelete>
  <div class="card-body">
    Etes-vous sûr de vouloir supprimer?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()">OUI</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
  </div>
</ng-template>

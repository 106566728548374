
    <script src="ajouterjoueur.component.ts"></script>

<!--      <h2 style="color: #007E3A; font-weight: bold;">Configuration e-mail pour envoyer le rapport</h2>-->

<!--      <h2 style="color: #1492ca; font-weight: normal; margin-top: 10px;">Importer le logo</h2>-->
<!--      <div *ngIf="logo != '' && logo != null">-->
<!--        <img alt="logo" src="{{urlimage}}" style="width: 200px; height: 200px; padding:15px; borderRadius:10px; margin-bottom: 30px;"/>-->
<!--      </div>-->
<!--      <div class="file-upload">-->
<!--        <input type="file" id="image"  #UploadFileInput name="uploadedImage" placeholder="Photo" (change)="onFileSelect($event)" >-->
<!--      </div>-->
<!--      <div class="form-group" style="width: 40%; margin-top: 25px;">-->
<!--        <label>Objet de l' e-mail</label>-->
<!--        <input type="text" class="form-control" name="bomImmatriculation" [(ngModel)]="objetEmail">-->
<!--      </div>-->
<!--      <button style="margin-bottom: 15px; margin-top: 15px;color:#ffffff; padding-left: 30px; padding-right: 30px;" class="btn btn-warning btn-sm pull-right" (click)="sendFile()">-->
<!--        Enregistrer-->
<!--      </button>-->

<!--///////////////////////////////////////////////////////////////-->
<!--///////////////////////////////////////////////////////////////-->
<!--///////////////////////////////////////////////////////////////-->
<!--///////////////////////////////////////////////////////////////-->
<!--///////////////////////////////////////////////////////////////-->
<!--///////////////////////////////////////////////////////////////-->
<!--///////////////////////////////////////////////////////////////-->

    <script src="../menu/menu.component.ts"></script>
    <div class="card-body">
      <div class="row col-5">
        <form>
          <div class="form-group">
            <div *ngIf="avatar != '' && avatar != null">
              <img alt="avatar" src="{{avatar}}" style="width: 200px; height: 200px; padding:15px; borderRadius:10px; margin-bottom: 30px;"/>
            </div>
                    <label for="avatar" style="color:#259547; margin-bottom: 20px;">Importer photo de profil de l'utilisateur</label>
                    <input type="file" accept="image/*" id="avatar" class="form-control-file"  #UploadFileInput name="uploadedImage" placeholder="Photo" (change)="onFileSelect($event)" >
          </div>

          <div style="display: flex; flex-direction: row;" >
            <div class="form-check" >
              <input [(ngModel)]="hommefemme" class="form-check-input" type="radio" name="radio" id="homme" value="H" >
              <label class="form-check-label" for="homme">
                Homme
              </label>
            </div>

            <div class="form-check" style="margin-left: 15px;" >
              <input [(ngModel)]="hommefemme"  class="form-check-input" type="radio" name="radio" id="femme" value="F">
              <label class="form-check-label" for="femme">
                Femme
              </label>
            </div>
          </div>
          <div class="form-group" style="margin-top: 15px;">
            <label for="nomjoueur" style="color:#259547;">Nom</label>
            <input type="text" class="form-control"  id="nomjoueur"  name="nomjoueur" placeholder="Nom du joueur*"  [(ngModel)]="first_name">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="prenomjoueur" style="color:#259547;">Prénoms</label>
            <input [(ngModel)]="last_name" type="text" class="form-control" name="prenomjoueur" id="prenomjoueur" placeholder="Prénoms du joueur*">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="nomutilisateur" style="color:#259547;">Nom d'utilisateur</label>
            <input [(ngModel)]="username" type="text" class="form-control" name="nomutilisateur" id="nomutilisateur" placeholder="Nom d'utilisateur*">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="datenaissance" style="color:#259547; ">Date de naissance* : </label>
            <input [(ngModel)]="datenaissance"  class="form-control" id="datenaissance" type="date"  value="" name="debut" >
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <div style="color:#259547; font-weight: bold; margin-bottom: 8px;">Position* : </div>
            <mat-form-field style="background-color: rgb(180,180,180);  width: 100%;" appearance="fill">
              <mat-label style="color: #2e2e2e;">Choisir au moins une position</mat-label>
              <mat-select (selectionChange)="changeClient($event.value)"  multiple>
                <mat-option style="color: #b2b2b2;" *ngFor="let item of listePosition" [value]="item.id">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>



          <div class="form-group" style="margin-top: 15px;">
            <label for="taille" style="color:#259547;">Taille en cm</label>
            <input [(ngModel)]="taille" type="text" class="form-control" name="taille" id="taille" placeholder="Taille*">
          </div>



          <div class="form-group" style="margin-top: 15px;">
            <label for="poids" style="color:#259547;">Poids en kg</label>
            <input [(ngModel)]="poids" type="text" class="form-control" name="poids" id="poids" placeholder="Poids">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label style="color:#259547;">Pied(s) fort(s)*</label>
            <div style="display: flex; flex-direction: row;" >
              <div class="form-check" >
                <input [(ngModel)]="droitegauchelesdeux"  class="form-check-input" type="radio" name="radio1" id="droite" value="DROITE" >
                <label class="form-check-label" for="droite">
                  Droite
                </label>
              </div>

              <div class="form-check" style="margin-left: 15px;" >
                <input [(ngModel)]="droitegauchelesdeux"   class="form-check-input" type="radio" name="radio1" id="gauche" value="GAUCHE">
                <label class="form-check-label" for="gauche">
                  Gauche
                </label>
              </div>

              <div class="form-check" style="margin-left: 15px;" >
                <input [(ngModel)]="droitegauchelesdeux"   class="form-check-input" type="radio" name="radio1" id="lesdeux" value="LESDEUX">
                <label class="form-check-label" for="lesdeux">
                  Les deux
                </label>
              </div>

            </div>
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="telephone" style="color:#259547;">Téléphone</label>
            <input [(ngModel)]="telephone"  type="text" class="form-control" aria-describedby="telephoneHelp" name="telephone"  id="telephone" placeholder="Téléphone*">
          </div>


          <div class="form-group" style="margin-top: 15px;">
            <label for="email" style="color:#259547;">Adresse e-mail</label>
            <input  [(ngModel)]="email"   type="email" class="form-control"  name="email" id="email" aria-describedby="emailHelp" placeholder="Email*">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="nationalite1" style="color:#259547;">Nationalité 1</label>
            <input  [(ngModel)]="nationalite1"  type="text" class="form-control"  name="nationalite1" id="nationalite1" placeholder="Nationalité 1*">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="nationalite2" style="color:#259547;">Nationalité 2</label>
            <input  [(ngModel)]="nationalite2"  type="text" class="form-control"   name="nationalite2"  id="nationalite2" placeholder="Nationalité 2 (facultatif)">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="region" style="color:#259547;">Région</label>
            <input [(ngModel)]="region"  type="text" class="form-control"  name="region"  id="region" placeholder="Région*">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="departement" style="color:#259547;">Département</label>
            <input [(ngModel)]="departement"  type="text" class="form-control"  name="departement" id="departement" placeholder="Département*">
          </div>


          <div class="form-group" style="margin-top: 15px;">
            <label style="color:#259547;">Disponibilité</label>
            <div style="display: flex; flex-direction: row;" >
              <div class="form-check" >
                <input (change)="libreorclub($event)"  [(ngModel)]="disponibilite" class="form-check-input" type="radio" name="radio2" id="libre" value="LIBRE" >
                <label class="form-check-label" for="libre">
                  Libre
                </label>
              </div>

              <div class="form-check" style="margin-left: 15px;" >
                <input   (change)="libreorclub($event)"  [(ngModel)]="disponibilite"  class="form-check-input" type="radio" name="radio2" id="enclub" value="ENCLUB">
                <label class="form-check-label" for="enclub">
                  En club
                </label>
              </div>

              <div class="form-check" style="margin-left: 15px;" >
                <input  (change)="libreorclub($event)" [(ngModel)]="disponibilite"  class="form-check-input" type="radio" name="radio2" id="souscontrat" value="SOUSCONTRAT">
                <label class="form-check-label" for="souscontrat">
                  Sous contrat
                </label>
              </div>
            </div>
          </div>

          <div *ngIf="disponibilite !== '' && disponibilite !== 'LIBRE'" class="form-group" style="margin-top: 20px;">
            <div style="color:#259547; font-weight: bold; margin-bottom: 8px;">Club : </div>
            <div style="display: flex; flex-direction: row; margin-bottom: 15px;" >
              <div class="form-check" >
                <input  [(ngModel)]="isnouveauclub" class="form-check-input" type="radio" name="radio" id="oui"  value="{{true}}" >
                <label class="form-check-label" for="oui">
                  Nouveau club
                </label>
              </div>

              <div class="form-check" style="margin-left: 15px;" >
                <input  [(ngModel)]="isnouveauclub"  class="form-check-input" type="radio" name="radio" id="non"  value="{{false}}">
                <label class="form-check-label" for="non">
                  Choisir le club
                </label>
              </div>
            </div>

            <div *ngIf="isnouveauclub == 'true'"  class="form-group" style="margin-top: 15px;">
              <label for="newClub" style="color:#259547;">Nom du nouveau club</label>
              <input [(ngModel)]="newClub" type="text" class="form-control" name="newClub" id="newClub" placeholder="Nom du nouveau club*">
            </div>
            <div *ngIf="isnouveauclub == 'true'" class="form-group" style="margin-top: 15px;">
              <label for="emailClub0" style="color:#259547;">E-mail du club</label>
              <input  [(ngModel)]="emailClub"   type="email" class="form-control"  name="emailClub" id="emailClub0" aria-describedby="emailHelp" placeholder="E-mail du club*">
            </div>

            <div *ngIf="isnouveauclub == 'true'" class="form-group" style="margin-top: 15px;">
              <label for="numeroAffiliation0" style="color:#259547;">Numéro d'affiliation du club</label>
              <input [(ngModel)]="numeroAffiliation" type="text" class="form-control" name="numeroAffiliation" id="numeroAffiliation0" placeholder="Numéro d'affiliation du club*">
            </div>

            <div *ngIf="isnouveauclub == 'false'">
              <div class="row" style="margin-top: 12px; margin-bottom: 15px; display: flex; flex-direction: row; align-items: center;">
                <div style="font-size: medium">&nbsp;&nbsp;&nbsp; Récuperer par index de : &nbsp;&nbsp;&nbsp;</div>
                <div><input id="index1" type="text" style="width:80px; " value="0" name="debut" [(ngModel)]="index1"></div>
                <div style="font-size: medium">&nbsp;&nbsp;&nbsp; à: &nbsp;&nbsp;&nbsp;</div>
                <input id="index2" type="text" value="150"  style="width:80px; " name="fin" [(ngModel)]="index2">
                <button style="margin-left: 15px;" class="btn btn-info btn-sm pull-right" (click)="filterByIndex(index1, index2)">
                  <i class="fa fa-list" aria-hidden="true"></i> Afficher
                </button>
              </div>
              <mat-form-field    style="background-color: rgb(180,180,180);  width: 35%;" appearance="fill">
                <mat-label style="color: #2e2e2e;">Choisir le club pour le joueur</mat-label>
                <mat-select (selectionChange)="changeClub($event.value)" >
                  <mat-option style="color: #b2b2b2;" *ngFor="let item of listeClub" [value]="item">{{item.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="password" style="color:#259547;">Mot de passe</label>
            <input [(ngModel)]="password"  type="text" class="form-control"  name="password" id="password" placeholder="Mot de passe*">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="confirmpassword" style="color:#259547;">Confirmer le mot de passe</label>
            <input [(ngModel)]="confirmpassword"  type="text" class="form-control"  name="confirmpassword" id="confirmpassword" placeholder="Confirmer le de passe*">
          </div>

          <button (click)="ajoutjoueur()" style="margin-top: 25px; margin-bottom: 25px; padding
          -left: 20px; padding-right: 20px;" type="button" class="btn btn-success">Ajouter le joueur</button>
        </form>
      </div>
    </div>










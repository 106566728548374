export * from './user/login/login.component'
export * from './user/register/register.component'
export * from './home/home.component'
export * from './menu/menu.component'
export * from './ajouterjoueur/ajouterjoueur.component'
export * from './modifierjoueur/modifierjoueur.component'
export * from './Listerjoueur/Listerjoueur.component'
export * from './afficherjoueur/afficherjoueur.component'
export * from './ajouterentraineur/ajouterentraineur.component'
export * from './Listerentraineur/Listerentraineur.component'
export * from './modifierentraineur/modifierentraineur.component'
export * from './position/position.component'
export * from './schemajeux/schemajeux.component'
export * from './user/modificationadmin/modificationadmin.component'
export * from './ajouterclubetmembre/ajouterclubetmembre.component'
export * from './ajouterclub/ajouterclub.component'
export * from './listerclub/listerclub.component'
export * from './modifierclub/modifierclub.component'
export * from './Listerclubetmembre/Listerclubetmembre.component'
export * from './modifierclubetmembre/modifierclubetmembre.component'
export * from './showimagejoueur/showimagejoueur.component'
export * from './showvideojoueur/showvideojoueur.component'
export * from './diplome/diplome.component'
export * from './categorie/categorie.component'
export * from './niveau/niveau.component'
export * from './Signalerjoueur/Signalerjoueur.component'
export * from './CGU/cgu.component'
export * from './user/validation_email/validation_email.component'
export * from './CGU_outsession/cgu_outsession.component'

<script src="modifierclubetmembre.component.ts"></script>
<script src="../menu/menu.component.ts"></script>
<div class="card-body">
  <div class="row col-5">
    <form>
      <div class="form-group">
        <div *ngIf="logo != '' && logo != null">
          <img alt="logo" src="{{logo}}" style="width: 200px; height: 200px; padding:15px; borderRadius:10px; margin-bottom: 30px;"/>
        </div>
        <label for="logo" style="color:#259547; margin-bottom: 20px;">Importer le logo du club</label>
        <input type="file"  accept="image/*" id="logo" class="form-control-file"  #UploadFileInput name="uploadedImage" placeholder="Photo" (change)="onFileSelect($event)" >
      </div>


      <div class="form-group" style="margin-top: 15px;">
        <label for="nommembreclub" style="color:#259547;">Nom</label>
        <input type="text" class="form-control"  id="nommembreclub"  name="nommembreclub" placeholder="Nom du membre du club*"  [(ngModel)]="first_name">
      </div>

      <div class="form-group" style="margin-top: 15px;">
        <label for="prenommembreclub" style="color:#259547;">Prénoms</label>
        <input [(ngModel)]="last_name" type="text" class="form-control" name="prenommembreclub" id="prenommembreclub" placeholder="Prénoms du membre du club*">
      </div>
      <div class="form-group" style="margin-top: 15px;">
        <label for="telephone" style="color:#259547;">Téléphone</label>
        <input [(ngModel)]="telephone"  type="text" class="form-control" aria-describedby="telephoneHelp" name="telephone"  id="telephone" placeholder="Téléphone du membre du club*">
      </div>

      <div class="form-group" style="margin-top: 15px;">
        <label for="adressemembreclub" style="color:#259547;">Adresse</label>
        <input [(ngModel)]="adresse" type="text" class="form-control" name="adressemembreclub" id="adressemembreclub" placeholder="Adresse du membre du club*">
      </div>

      <div class="form-group" style="margin-top: 15px;">
        <label for="email" style="color:#259547;">Adresse e-mail</label>
        <input  [(ngModel)]="email"   type="email" class="form-control"  name="email" id="email" aria-describedby="emailHelp" placeholder="E-mail du membre du club*">
      </div>

      <div class="form-group" style="margin-top: 20px;">
        <div style="color:#259547;  font-weight: bold; margin-bottom: 8px;">Club* : <span  style="color:#5c5695;margin-bottom: 8px;">{{selectedclubname}}</span></div>

        <div style="display: flex; flex-direction: row; margin-bottom: 15px;" >
          <div class="form-check" >
            <input (change)="onModifyChoiceClub($event)" [(ngModel)]="isnouveauclub" class="form-check-input" type="radio" name="radio" id="oui"  value="{{true}}" >
            <label class="form-check-label" for="oui">
              Nouveau club
            </label>
          </div>

          <div class="form-check" style="margin-left: 15px;" >
            <input (change)="onModifyChoiceClub($event)" [(ngModel)]="isnouveauclub"  class="form-check-input" type="radio" name="radio" id="non"  value="{{false}}">
            <label class="form-check-label" for="non">
              Choisir le club
            </label>
          </div>
        </div>

        <div *ngIf="isnouveauclub == 'true'"  class="form-group" style="margin-top: 15px;">
          <label for="newClub" style="color:#259547;">Nom du nouveau club</label>
          <input [(ngModel)]="newClub" type="text" class="form-control" name="newClub" id="newClub" placeholder="Nom du nouveau club*">
        </div>

        <div *ngIf="isnouveauclub == 'false'">
          <div class="row" style="margin-top: 12px; margin-bottom: 15px; display: flex; flex-direction: row; align-items: center;">
            <div style="font-size: medium">&nbsp;&nbsp;&nbsp; Récuperer par index de : &nbsp;&nbsp;&nbsp;</div>
            <div><input id="index1" type="text" style="width:60px; " value="0" name="debut" [(ngModel)]="index1"></div>
            <div style="font-size: medium">&nbsp;&nbsp;&nbsp; à: &nbsp;&nbsp;&nbsp;</div>
            <input id="index2" type="text" value="150"  style="width:60px; " name="fin" [(ngModel)]="index2">
            <button style="margin-left: 15px;" class="btn btn-success btn-sm pull-right" (click)="filterByIndex(index1, index2)">
              <i class="fa fa-list" aria-hidden="true"></i> Afficher
            </button>
          </div>
          <mat-form-field    style="background-color: rgb(180,180,180);  width: 100%;" appearance="fill">
            <mat-label style="color: #2e2e2e;">Choisir le club pour l'employé</mat-label>
            <mat-select (selectionChange)="changeClub($event.value)" >
              <mat-option style="color: #b2b2b2;" *ngFor="let item of listeClub" [value]="item">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div  *ngIf="isnouveauclub == 'true'" class="form-group" style="margin-top: 15px;">
        <label for="emailClub0" style="color:#259547;">E-mail du club</label>
        <input  [(ngModel)]="emailClub"   type="email" class="form-control"  name="emailClub" id="emailClub0" aria-describedby="emailHelp" placeholder="E-mail du club*">
      </div>
      <div *ngIf="isnouveauclub == 'true'" class="form-group" style="margin-top: 15px;">
        <label for="numeroAffiliation0" style="color:#259547;">Numéro d'affiliation du club</label>
        <input [(ngModel)]="numeroAffiliation" type="text" class="form-control" name="numeroAffiliation" id="numeroAffiliation0" placeholder="Numéro d'affiliation du club*">
      </div>

<!--      <div  *ngIf="isnouveauclub == 'false'" class="form-group" style="margin-top: 15px;">-->
<!--        <label for="emailClub" style="color:#259547;">E-mail du club</label>-->
<!--        <input  [(ngModel)]="emailClub"   type="email" class="form-control"  name="emailClub" id="emailClub" aria-describedby="emailHelp" placeholder="E-mail du club*">-->
<!--      </div>-->
<!--      <div *ngIf="isnouveauclub == 'false'" class="form-group" style="margin-top: 15px;">-->
<!--        <label for="numeroAffiliation" style="color:#259547;">Numéro d'affiliation du club</label>-->
<!--        <input [(ngModel)]="numeroAffiliation" type="text" class="form-control" name="numeroAffiliation" id="numeroAffiliation" placeholder="Numéro d'affiliation du club*">-->
<!--      </div>-->

      <div class="form-group" style="margin-top: 15px;">
        <label for="fonctionAuClub" style="color:#259547;">Fonction du membre au sein du club</label>
        <input [(ngModel)]="fonctionAuClub" type="text" class="form-control" name="fonctionAuClub" id="fonctionAuClub" placeholder="Fonction du membre au sein du club*">
      </div>

      <div class="form-group" style="margin-top: 15px;">
        <label for="nomutilisateur" style="color:#259547;">Nom d'utilisateur</label>
        <input [(ngModel)]="username" type="text" class="form-control" name="nomutilisateur" id="nomutilisateur" placeholder="Nom d'utilisateur de l'employé du club*">
      </div>

      <div  class="form-group" style="margin-top: 15px;">
        <div style="margin-top: 20px; margin-bottom: 10px;" >
          <input name="ismodifierpassword" id="ismodifierpassword" type="checkbox" (change)="ismodifierpassword($event)" >
          <label  style="margin-left: 10px; color: #1da1f2; font-weight: bold;" class="form-check-label" for="ismodifierpassword">
            Modifier le mot de passe
          </label>
        </div>
        <label *ngIf="ismodifierpasswordvar === true" for="password" style="color:#259547;">Mot de passe</label>
        <input *ngIf="ismodifierpasswordvar === true" [(ngModel)]="password"  type="text" class="form-control"  name="password" id="password" placeholder="Mot de passe*">
      </div>

      <div *ngIf="ismodifierpasswordvar === true" class="form-group" style="margin-top: 15px;">
        <label for="confirmpassword" style="color:#259547;">Confirmer le mot de passe</label>
        <input [(ngModel)]="confirmpassword"  type="text" class="form-control"  name="confirmpassword" id="confirmpassword" placeholder="Confirmer le de passe*">
      </div>

      <button (click)="modifierclub()" style="margin-top: 25px; margin-bottom: 25px; padding-left: 20px; padding-right: 20px;" type="button" class="btn btn-success">Modifier</button>
    </form>
  </div>
</div>










<div class="card-body">

  <app-date-filter1 (itemConcat1)="filterByDate($event)" (itemListAll1)="loadListeclubetmembre()"></app-date-filter1>

  <div class="row" style="margin-top: 15px;">
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
      <thead>
      <tr>
        <th><div>Active</div>/desactive</th>
        <th>Club</th>
        <th>Nom d'utilisateur</th>
        <th>Nom et Prénoms</th>
        <th>email</th>
        <th>Fonction au club</th>
        <th>Téléphone</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of listClubetmembre" class="pointer-cl">
        <td >
          <input style="margin-left: 35%" type="checkbox" (change)="activedesactive(item?.id, $event)" [checked]="item.enabled ==='ACTIVE'" >
        </td>
        <td>{{(item.clubactuel && true && item.clubactuel.length > 0) ? (item.clubactuel[0].name  | titlecase) : '' }}</td>
        <td>{{item.username !== null ? item.username : ''}}</td>
        <td>{{item.last_name !== null ? item.last_name : ''}}{{" "}}{{item.first_name !== null ? item.first_name : ''}}</td>
        <td>{{item?.email | lowercase}}</td>
        <td>{{item.fonctionAuClub !== null ? (item?.fonctionAuClub | titlecase) : ''}}</td>
        <td>{{item.phone ? item.phone : ''}}</td>
        <td style="display: flex; flex-direction: row">
          <button style="margin-right: 5px;" class="btn btn-sm btn-success"  (click)="emitNavigateToModifyClubetmembre(item.id)">
            <i class="fas fa-edit"></i>
          </button>
          <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
            <i class="fas fa-trash"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


<ng-template #confirmDelete>
  <div class="card-body">
    Etes-vous sûr de vouloir supprimer?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()">OUI</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
  </div>
</ng-template>

import {Component, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from "ngx-spinner";
import { Time } from "@angular/common";
import set = Reflect.set;
import {JoueurService} from "../../services/joueur.service";
import {AdminService} from "../../services/admin.service";

@Component({
  selector: 'app-showvideojoueur',
  templateUrl: './showvideojoueur.component.html',
  styleUrls: ['./showvideojoueur.component.css']
})
export class showvideojoueurComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  @ViewChild('confirmDelete') confirmDelete: any;
  @Output() navigateToModifyClubetmembre: EventEmitter<any> = new EventEmitter();
  @ViewChild('showVideo') showVideo: any;
  @ViewChild('confirmCheckDeletePls') confirmCheckDeletePls: any;

  public oneurlvideo: string = "";
  public userId: string = "";
  public listVideos: string = "";
  public videoId: any = null;
  public dtOptions: any = {};
  public dtTigger = new Subject();
  public emitData: EventEmitter<any> = new EventEmitter;
  public checkEnableDisablePlusieursArray: any = [];

  constructor(
    private joueurService: JoueurService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private adminService: AdminService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(()=>{
      this.spinner.hide()
    },10000);
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100],
      "order": [[ 1, 'desc' ]]
    };

    this.userId = localStorage.getItem('modificationId');
    const token = localStorage.getItem('userToken');

    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},10000);
    this.joueurService.getAllmyvideos(token, this.userId)
      .subscribe(async (res: any) => {
        this.listVideos = res.data;
        console.log("this.listVideos",this.listVideos);
        this.dtTigger.next();
        this.spinner.hide()
      })
  }

  /**
   * loadListe
   */
  public load() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.userId = localStorage.getItem('modificationId');
      let token = localStorage.getItem('userToken');
      let adminId = localStorage.getItem('adminId');
      this.adminService.gettokenbyid(adminId)
        .subscribe(async (res: any) => {
          localStorage.setItem('userToken', res.data);
          token = res.data;
        });
      this.spinner.show();
      setTimeout(()=>{this.spinner.hide()},10000);
      this.joueurService.getAllmyvideos(token, this.userId)
        .subscribe(async (res: any) => {
          this.listVideos = res.data;
          this.dtTigger.next();
          this.spinner.hide()
        });
    })
  }

  public openModaPourSupprimerPlusieurs() {
    this.ngbModal.open(this.confirmCheckDeletePls)
  }


   public async supprimerPlusieurs() {
     let token =  await localStorage.getItem('userToken');
     let adminId = localStorage.getItem('adminId');
     this.adminService.gettokenbyid(adminId)
       .subscribe(async (res: any) => {
         localStorage.setItem('userToken', res.data);
         token = res.data;
     });
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},10000);
    this.joueurService.supprimerPlusieursmedias(this.checkEnableDisablePlusieursArray, token)
      .subscribe((res: any) => {
        this.load();
        this.resetField();
        this.spinner.hide()
      })
  }

  public checkEnableDisablePlusieurs(id,event){
    if(event.target.checked) {
      let item = 0;
      for(let i = 0; i<this.checkEnableDisablePlusieursArray.length; i++){
        if(this.checkEnableDisablePlusieursArray[i] == id){
          item++;
        }
      }
      if(item === 0 ){
        this.checkEnableDisablePlusieursArray.push(id);
      }
    }else{
      this.checkEnableDisablePlusieursArray = this.checkEnableDisablePlusieursArray.filter(function (item) {
        return item !== id;
      });
    }
  }


    /**
   * filterByDate
   */
  public filterByDate(item) {
    let dateSplte = item.split(',');
    this.getBy2date(dateSplte[0], dateSplte[1])
  }

  public async getBy2date(start, end) {
    if(start !== undefined && end !== undefined){
      this.spinner.show();
    setTimeout(()=>{
      this.spinner.hide()
    },10000);
    let token =  await localStorage.getItem('userToken');
      let adminId = localStorage.getItem('adminId');
      this.adminService.gettokenbyid(adminId)
        .subscribe(async (res: any) => {
          localStorage.setItem('userToken', res.data);
          token = res.data;
      });
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      let body = {
        startDate: start,
        endDate: end,
        id:this.userId
      };
        this.joueurService.getmediaBetweenTwoDates(token, body)
          .subscribe((res: any) => {
            this.spinner.hide();
            this.listVideos = res.data;
            this.dtTigger.next()
          })
    })
  }}


  /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.videoId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }


  public  parsejson(jsonotparsed: any) {
    return  JSON.parse(jsonotparsed);
  }


  /**
   * delete
   */
  public async delete() {
    this.spinner.show();
    setTimeout(()=>{
      this.spinner.hide()
    },10000);
    let token =  await localStorage.getItem('userToken');
    let adminId = localStorage.getItem('adminId');
    this.adminService.gettokenbyid(adminId)
      .subscribe(async (res: any) => {
        localStorage.setItem('userToken', res.data);
        token = res.data;
      });
    this.joueurService.deleteonemedia(token, this.videoId)
      .subscribe((res: any) => {
        this.load();
        this.resetField();
        this.spinner.hide();
      })
  }

  public showsVideo(urlvideo) {
    this.oneurlvideo = urlvideo;
    this.ngbModal.open(this.showVideo, {windowClass: 'lg'})
  }

  public resetField() {
    this.ngbModal.dismissAll();
     this.videoId = '';
  }
  /**
   * openModal
   */
  public openModal(modal) {
    this.ngbModal.open(modal)
  }

  // GEstion field data
  public closeModal() {
    this.ngbModal.dismissAll()
  }



}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

// CMP
import {
  HomeComponent,
  LoginComponent,
  Validation_emailComponent, Cgu_outsessionComponent
} from '../application';
// ROUTE
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'cgu', component: Cgu_outsessionComponent },
  { path: 'confirmation/:param', component: Validation_emailComponent },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'front/login', component: LoginComponent },
  { path: 'front/home', component: HomeComponent },

];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class RouteModule { }

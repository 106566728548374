import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  OnChanges,
  OnDestroy,
  ElementRef,
  LOCALE_ID,
  Inject,
  Output
} from '@angular/core';
import { ClubService } from 'src/app/services/club.service';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import {NgbAlert, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import {NgxSpinnerService} from "ngx-spinner";
import {DatePipe, formatDate} from "@angular/common";
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import * as moment from 'moment';
import {ENV_PROD} from "../../../environments/config-env";
import { Router } from '@angular/router';
//-------------------------------------------
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {catchError, retry} from "rxjs/operators";
import {Admin} from "../../constant/Position";
import {AdminService} from "../../services/admin.service";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'app-ajouterclubetmembre',
  templateUrl: './ajouterclubetmembre.component.html',
  styleUrls: ['./ajouterclubetmembre.component.css']
})

export class ajouterclubetmembreComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;

  fileUploadForm: FormGroup;
  fileInputLabel: string;
  public dtTigger = new Subject();
  public listeClub: any = [];
  public isnouveauclub: any = null;
  public avatar: any = "";
  public first_name: string = "";
  public last_name: string = "";
  public telephone: string = "";
  public adresse: string = "";
  public email: string = "";
  public club: string = null;
  public newClub: string = "";
  public emailClub: string = "";
  public numeroAffiliation: string = "";
  public fonctionAuClub: string = "";
  public username: string = "";
  public password: string = "";
  public confirmpassword: string = "";
  public index1: any = 0;
  public index2: any = 150;

  constructor(
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private clubService: ClubService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private router: Router,
    private adminService: AdminService,
    private alert: AlertService,
    @Inject(LOCALE_ID) private locale: string

  ) {

  }


  ngOnInit(): void {
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},10000);
    this.clubService.getListeclub()
      .subscribe(async (res: any) => {
        this.listeClub = res.data;
        this.spinner.hide();
      });

       this.fileUploadForm = this.formBuilder.group({
      uploadedImage: [''],
    });
  }


  ngOnDestroy(): void {
    this.dtTigger.unsubscribe()
  }


  ngOnChanges(): void {
    this.load();
  }

  load(){
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},10000);
    this.clubService.getListeclub()
      .subscribe(async (res: any) => {
        this.listeClub = res.data;
        this.spinner.hide();
      });
  }


  onFileSelect(event) {
    const file = event.target.files[0];
    this.fileInputLabel = file.name;
    this.fileUploadForm.get('uploadedImage').setValue(file);
  }

  public async changeClient(item: any) {
    this.club = item;
  }

  async ajoutclub(){
    if(this.first_name === ""){
      this.alert.emptyField("Veuillez inserer le nom du membre du club.");
      return;
    }
    if(this.last_name === ""){
      this.alert.emptyField("Veuillez inserer le prénoms du membre du club.");
      return;
    }
    if(this.telephone === ""){
      this.alert.emptyField("Veuillez insérer le numéro de téléphone du membre du club.");
      return;
    }

    if(this.telephone !== ""  && !this.adminService.validatePhoneNumber(this.telephone.trim())){
      this.alert.emptyField("Veuillez insérer un numéro de téléphone valide.");
      return;
    }

    if(this.adresse === ""){
      this.alert.emptyField("Veuillez insérer l'adresse du membre du club.");
      return;
    }
    if(this.email === ""){
      this.alert.emptyField("Veuillez insérer l'email du membre du club.");
      return;
    }
    if(this.email !== "" && !this.adminService.validateEMail(this.email)){
      this.alert.emptyField("Veuillez insérer un adresse e-mail valide.");
      return;
    }
    if(this.isnouveauclub == null && this.club === null && this.newClub === ""){
      this.alert.emptyField("Veuillez choisir le club pour le membre.");
      return;
    }
    if(this.isnouveauclub == 'true' &&  this.newClub === ""){
      this.alert.emptyField("Veuillez inserer le nom du nouveau club.");
      return;
    }
    if( this.isnouveauclub === 'false' && this.club === null){
      this.alert.emptyField("Veuillez choisir le club.");
      return;
    }
    if(this.isnouveauclub == 'true' && this.emailClub === ""){
      this.alert.emptyField("Veuillez insérer l'email du club.");
      return;
    }
    if(this.emailClub !== "" && !this.adminService.validateEMail(this.emailClub)){
      this.alert.emptyField("Veuillez insérer un adresse e-mail valide.");
      return;
    }

    if(this.isnouveauclub == 'true' && this.numeroAffiliation === ""){
      this.alert.emptyField("Veuillez insérer le numéro d'affiliation du club.");
      return;
    }

    if(this.fonctionAuClub === ""){
      this.alert.emptyField("Veuillez inserer la fonction du membre au sein du club.");
      return;
    }
    if(this.username === ""){
      this.alert.emptyField("Veuillez inserer le nom d'utilisateur du membre du club.");
      return;
    }

    if(this.password === ""){
      this.alert.emptyField("Veuillez inserer le mot de passe pour le membre du club.");
      return;
    }
    if(this.password !== this.confirmpassword){
      this.alert.emptyField("Le mot de passe et la confirmation du mot de passe n'est pas identique.");
      return;
    }

    let parent = {};
    const data = {
      first_name:this.first_name.trim(),
      last_name:this.last_name.trim(),
      phone: this.telephone.trim(),
      adresse:this.adresse.trim(),
      email:this.email.trim().toLowerCase(),
      username:this.username.trim(),
      fonctionAuClub:this.fonctionAuClub.trim(),
      //------------
      role:"CLUB",
      password:this.password,
      checkCondition:true

    };
    if(this.isnouveauclub === 'true'){
      parent["club"] ={ id:0 };
      parent["newClub"] = this.newClub;
    }else {
      parent["club"] = this.club;
    }
    parent["emailClub"] = this.emailClub.trim().toLowerCase();
    parent["numeroAffiliation"] = this.numeroAffiliation.trim();

    this.spinner.show();
    setTimeout(()=>{
      this.spinner.hide();
    },12000);
    const formData = new FormData();
    let headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin' : '*',
    });
    let options = { headers: headers };
    formData.append('data', this.fileUploadForm.get('uploadedImage').value);
    this.uploadFileInput.nativeElement.value = '';
    if (this.fileUploadForm.get('uploadedImage').value){
      this.httpClient.post<any>(ENV_PROD.urlApi + 'upload-image',
        formData
        // , options
      ).subscribe(
        (response) => {
          if (response.status === 200) {
            this.avatar = response.urlfile;
            parent['logo'] = response.nameFile;
            parent['user'] = data;
            this.fileInputLabel = undefined;
            this.httpClient.post<any>(ENV_PROD.urlApi + 'inscriptionmembreclub', parent).subscribe(
              (response1) => {
                this.spinner.hide();
                if (response1.status === 200){
                  alert(ENV_PROD.nom_application + " \n " + response1.message);
                }else if (response1.status === 404 || response1.status === 300 || response1.status === 301){
                  alert(ENV_PROD.nom_application + " \n " +  response1.message);
                }})
          }else{
            alert(ENV_PROD.nom_application + " \n " + "L'image n'a pas pu être envoyée.");
          }
        })
    }else{
      parent['user'] = data;
      this.fileInputLabel = undefined;
      this.httpClient.post<any>(ENV_PROD.urlApi + 'inscriptionmembreclub', parent).subscribe(
        (response1) => {
          this.spinner.hide();
          if (response1.status === 200){
            alert(ENV_PROD.nom_application + " \n " + response1.message);
          }else if (response1.status === 404 || response1.status === 300 || response1.status === 301){
            alert(ENV_PROD.nom_application + " \n " +  response1.message);
          }})
    }
  }

  public filterByIndex(index1, index2) {
    if(parseInt(index2) < parseInt(index1)){
      alert("Le deuxième champ ne pourrait être pas inférieur au premier champ.");
      return;
    }
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},12000);
    if(index1 !== "" && index2 !== ""){
      this.clubService.getClubbyIndex(index1, index2)
        .subscribe((res: any) => {
          this.listeClub = res.data;
          this.spinner.hide()
        })
    }
  }

}

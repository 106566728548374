<!--<div class="btn-group btn-periode" role="group" aria-label="Basic example">-->
<!--    <button type="button" class="btn btn-info" (click)="filterByDate(today, today)">Aujourd'hui</button>-->
<!--    <button type="button" class="btn btn-info" (click)="filterByDate(yesterday, yesterday)">Hier</button>-->
<!--    <button type="button" class="btn btn-info" (click)="filterByDate(weeklyBegin, weeklyEnd)">Semaine</button>-->
<!--    <button type="button" class="btn btn-info" (click)="filterByDate(monthlyBegin, monthlyEnd)">Mois</button>-->
<!--    <button type="button" class="btn btn-info" (click)="checkAll()">Tous</button>-->
<!--</div>-->
<div class="row" style="margin-top: 12px; display: flex; flex-direction: row; align-items: center;">
    <div style="font-size: medium">&nbsp;&nbsp;&nbsp; Date de création entre le : &nbsp;&nbsp;&nbsp;</div>
    <div><input id="date" type="date" value="" class="form-control" name="debut" [(ngModel)]="dateBegin"></div>
    <div style="font-size: medium">&nbsp;&nbsp;&nbsp; A: &nbsp;&nbsp;&nbsp;</div>
    <input id="date1" type="date" value="" class="form-control"  style="width: 195px" name="fin" [(ngModel)]="dateEnd">
    <button style="margin-left: 15px;" class="btn btn-success btn-sm pull-right" (click)="filterByDate(dateBegin, dateEnd)">
        <i class="fa fa-list" aria-hidden="true"></i> Afficher
    </button>
</div>

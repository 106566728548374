import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-date-filter1',
  templateUrl: './date-filter1.component.html',
  styleUrls: ['./date-filter1.component.css']
})
export class DateFilter1Component implements OnInit {

  @Output() itemConcat1: EventEmitter<any> = new EventEmitter();
  @Output() itemListAll1: EventEmitter<any> = new EventEmitter();

  public today: any;
  public yesterday: any;
  public weeklyBegin: any;
  public weeklyEnd: any;
  public monthlyBegin: any;
  public monthlyEnd: any;
  public dateBegin: any;
  public dateEnd: any;
  public date: Date = new Date();

  constructor() { }

  ngOnInit(): void {
    // this.today = this.dateBegin = this.dateEnd = this.dateFormater(this.date);
    this.today = this.dateFormater(this.date);
    // this.yesterday = this.dateSub(this.dateFormater(this.date), 1);
    this.dateWeekly();
    this.dateMonthly()
  }

  /**
   * emitDate
   */
  public emitDate(itemConcat) {
    this.itemConcat1.emit(itemConcat)
  }

  /**
   * filterByDate
   */
  public filterByDate(firstDate, secondDate) {
    const dateConcat = firstDate + ',' + secondDate;
    this.emitDate(dateConcat)
  }

  /**
   * checkAll
   */
  public checkAll() {
    this.itemListAll1.emit()
  }

  /**
   * dateFormater
   */
  public dateFormater(date: Date) {
    return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate()
  }

  /**
  * dateSub
  */
  public dateSub(dateNow, number) {
    let date = new Date(dateNow);
    let dateSub = new Date(new Date().setDate(date.getDate() - number));
    return this.dateFormater(dateSub)
  }

  /**
   * dateAdd
   */
  public dateAdd(dateNow, number) {
    let date = new Date(dateNow);
    let dateAdd = new Date(new Date().setDate(date.getDate() + number));
    return this.dateFormater(dateAdd)
  }

  /**
   * dateWeekly
   */
  public dateWeekly() {
    let date = new Date();
    let day = date.getDay();
    this.weeklyBegin = this.dateSub(this.dateFormater(this.date), day);
    this.weeklyEnd = this.dateAdd(this.weeklyBegin, 6)
  }

  /**
   * dateMouthly
   */
  public dateMonthly() {
    let month = this.today.slice(5, 7);
    let year = this.today.slice(0, 4);
    let dayInMonth = new Date(parseInt(year), parseInt(month), 1);
    this.monthlyBegin = this.today.slice(0, 8) + '01';
    this.monthlyEnd = this.today.slice(0, 8) + (dayInMonth.getUTCDate())
  }

}

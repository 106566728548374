
    <script src="afficherjoueur.component.ts"></script>
    <script src="../menu/menu.component.ts"></script>
    <div class="card-body">
      <div class="row">
        <form>
          <div style="display: flex; flex-direction: row; align-items: center;" >
            <div class="form-group">
              <div *ngIf="avatar != '' && avatar != null">
                <img alt="avatar" src="{{avatar}}" style="width: 230px; height: 200px; padding:15px; borderRadius:10px; margin-bottom: 30px;"/>
              </div>
              <label for="avatar" style="color:#259547; margin-bottom: 20px;">Importer photo de profil de l'utilisateur</label>
              <input type="file" id="avatar" class="form-control-file"  #UploadFileInput name="uploadedImage" placeholder="Photo" (change)="onFileSelect($event)" >
            </div>

            <div class="form-group">
              <h3 *ngIf="hommefemme ==='H'">Homme</h3>
              <h3 *ngIf="hommefemme ==='F'">Femme</h3>
              <div class="form-group" style="margin-top: 8px; display: flex; flex-direction: row;">
                <h3 >Nom:</h3>
                <h3 style="margin-left: 8px;">{{first_name}}</h3>
              </div>
            </div>

          </div>



          <div class="form-group" style="margin-top: 15px;">
            <label for="prenomjoueur" style="color:#259547;">Prénoms</label>
            <input [(ngModel)]="last_name" type="text" class="form-control" name="prenomjoueur" id="prenomjoueur" placeholder="Prénoms du joueur*">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="nomutilisateur" style="color:#259547;">Nom d'utilisateur</label>
            <input [(ngModel)]="username" type="text" class="form-control" name="nomutilisateur" id="nomutilisateur" placeholder="Nom d'utilisateur*">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="datenaissance" style="color:#259547;">Date de naissance* : </label>
            <div><input [(ngModel)]="datenaissance" id="datenaissance" type="date" value="" name="debut" ></div>
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <div style="color:#259547;  font-weight: bold; margin-bottom: 8px;">Position* : <span style="color:#5c5695;margin-bottom: 8px;">{{selectedpositionname}}</span></div>
            <mat-form-field style="background-color: rgb(180,180,180);  width: 100%;" appearance="fill">
              <mat-label style="color: #2e2e2e;">Choisir au moins une position</mat-label>
              <mat-select  (selectionChange)="changeClient($event.value)"  multiple>
                <mat-option style="color: #b2b2b2;" *ngFor="let item of listePosition" [value]="item.id">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="taille" style="color:#259547;">Taille en cm</label>
            <input [(ngModel)]="taille" type="text" class="form-control" name="taille" id="taille" placeholder="Taille*">
          </div>



          <div class="form-group" style="margin-top: 15px;">
            <label for="poids" style="color:#259547;">Poids en kg</label>
            <input [(ngModel)]="poids" type="text" class="form-control" name="poids" id="poids" placeholder="Poids">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label style="color:#259547;">Pied(s) fort(s)*</label>
            <div style="display: flex; flex-direction: row;" >
              <div class="form-check" >
                <input [(ngModel)]="droitegauchelesdeux"  class="form-check-input" type="radio" name="radio1" id="droite" value="DROITE" >
                <label class="form-check-label" for="droite">
                  Droite
                </label>
              </div>

              <div class="form-check" style="margin-left: 15px;" >
                <input [(ngModel)]="droitegauchelesdeux"   class="form-check-input" type="radio" name="radio1" id="gauche" value="GAUCHE">
                <label class="form-check-label" for="gauche">
                  Gauche
                </label>
              </div>

              <div class="form-check" style="margin-left: 15px;" >
                <input [(ngModel)]="droitegauchelesdeux"   class="form-check-input" type="radio" name="radio1" id="lesdeux" value="LESDEUX">
                <label class="form-check-label" for="lesdeux">
                  Les deux
                </label>
              </div>

            </div>
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="telephone" style="color:#259547;">Téléphone</label>
            <input [(ngModel)]="telephone"  type="text" class="form-control" aria-describedby="telephoneHelp" name="telephone"  id="telephone" placeholder="Téléphone*">
          </div>


          <div class="form-group" style="margin-top: 15px;">
            <label for="email" style="color:#259547;">Adresse e-mail</label>
            <input  [(ngModel)]="email"   type="email" class="form-control"  name="email" id="email" aria-describedby="emailHelp" placeholder="Email*">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="nationalite1" style="color:#259547;">Nationalité 1</label>
            <input  [(ngModel)]="nationalite1"  type="text" class="form-control"  name="nationalite1" id="nationalite1" placeholder="Nationalité 1*">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="nationalite2" style="color:#259547;">Nationalité 2</label>
            <input  [(ngModel)]="nationalite2"  type="text" class="form-control"   name="nationalite2"  id="nationalite2" placeholder="Nationalité 2 (facultatif)">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="region" style="color:#259547;">Région</label>
            <input [(ngModel)]="region"  type="text" class="form-control"  name="region"  id="region" placeholder="Région*">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="departement" style="color:#259547;">Département</label>
            <input [(ngModel)]="departement"  type="text" class="form-control"  name="departement" id="departement" placeholder="Département*">
          </div>


          <div class="form-group" style="margin-top: 15px;">
            <label style="color:#259547;">Disponibilité</label>
            <div style="display: flex; flex-direction: row;" >
              <div class="form-check" >
                <input [(ngModel)]="disponibilite" class="form-check-input" type="radio" name="radio2" id="libre" value="LIBRE" >
                <label class="form-check-label" for="libre">
                  Libre
                </label>
              </div>

              <div class="form-check" style="margin-left: 15px;" >
                <input [(ngModel)]="disponibilite"  class="form-check-input" type="radio" name="radio2" id="enclub" value="ENCLUB">
                <label class="form-check-label" for="enclub">
                  En club
                </label>
              </div>

              <div class="form-check" style="margin-left: 15px;" >
                <input [(ngModel)]="disponibilite"  class="form-check-input" type="radio" name="radio2" id="souscontrat" value="SOUSCONTRAT">
                <label class="form-check-label" for="souscontrat">
                  Sous contrat
                </label>
              </div>

            </div>
          </div>



          <div class="form-group" style="margin-top: 15px;">
            <div style="margin-top: 20px; margin-bottom: 10px;" >
              <input name="ismodifierpassword" id="ismodifierpassword" type="checkbox" (change)="ismodifierpassword($event)" >
              <label  style="margin-left: 10px; color: #1da1f2; font-weight: bold;" class="form-check-label" for="ismodifierpassword">
                Modifier le mot de passe
              </label>
            </div>
            <label for="departement" style="color:#259547;">Mot de passe</label>
            <input [(ngModel)]="password"  type="text" class="form-control"  name="password" id="password" placeholder="Mot de passe*">
          </div>

          <div class="form-group" style="margin-top: 15px;">
            <label for="departement" style="color:#259547;">Confirmer le de passe</label>
            <input [(ngModel)]="confirmpassword"  type="text" class="form-control"  name="confirmpassword" id="confirmpassword" placeholder="Confirmer le de passe*">
          </div>

          <button (click)="modifierjoueur()" style="margin-top: 25px; margin-bottom: 25px; padding-left: 20px; padding-right: 20px;" type="button" class="btn btn-success">Modifier joueur</button>
        </form>
      </div>
    </div>










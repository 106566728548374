<div class="card-body">

  <app-date-filter1 (itemConcat1)="filterByDate($event)" (itemListAll1)="load()"></app-date-filter1>

  <div class="row" style="margin-top: 15px;">
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
      <thead>
      <tr>
        <th></th>
        <th>Crée le</th>
        <th>Vidéos</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of listVideos" class="pointer-cl">
        <td >
          <input style="margin-left: 35%" type="checkbox" (change)="checkEnableDisablePlusieurs(item?.id, $event)" >
        </td>
        <td>{{item.createdAt | date:'yyyy-MM-dd'}}</td>
        <td (click)="showsVideo(item.urlvideo)"><div *ngIf="item.video != '' && item.video != null">
<!--          <img alt="photos" src="{{item.urlvideo}}" style="width: 100px; height: 78px; padding:5px; borderRadius:3px; margin: 4px;"/>-->
          <video  width="300" height="150">
            <source  src="{{item.urlvideo}}#t=12" type="video/mp4" />
          </video>
        </div></td>
        <td style="display: flex; flex-direction: row">
          <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
            <i class="fas fa-trash"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <button class="btn btn-sm btn-danger" (click)="openModaPourSupprimerPlusieurs()">
    Supprimer les items cochés
  </button>
</div>

<ng-template #confirmCheckDeletePls>
  <div class="card-body">
    Êtes-vous sûr de vouloir supprimer ces items?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="supprimerPlusieurs()">OUI</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
  </div>
</ng-template>

<ng-template #confirmDelete>
  <div class="card-body">
    Etes-vous sûr de vouloir supprimer?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()">OUI</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
  </div>
</ng-template>

<ng-template #showVideo>
  <div style="width: 480px; height: 400px; display: flex; align-items: center; justify-content: center;">

    <video autoplay  style="width: 400px; height: 400px;">
      <source  src="{{oneurlvideo}}#t=12" type="video/mp4" />
    </video>
  </div>
</ng-template>

import {Component, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import { EntraineurService } from 'src/app/services/entraineur.service';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from "ngx-spinner";
import { Time } from "@angular/common";
import set = Reflect.set;
import {ClubService} from "../../services/club.service";

@Component({
  selector: 'app-listerclub',
  templateUrl: './listerclub.component.html',
  styleUrls: ['./listerclub.component.css']
})
export class ListerclubComponent implements OnInit {

  @ViewChild('actionVoyage') actionVoyage: any;
  @ViewChild('actionShowVoyage') actionShowVoyage: any;
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  @ViewChild('confirmDelete') confirmDelete: any;
  @Output() navigateToModifyClub: EventEmitter<any> = new EventEmitter();


  public search: string = "";
  public clubId: any = null;
  public listClub: any = [];
  public index1: any = 0;
  public index2: any = 150;
  public dtOptions: any = {};
  public dtTigger = new Subject();
  public emitData: EventEmitter<any> = new EventEmitter;

  constructor(
    private clubService: ClubService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService
  ) {

  }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},12000);
    setTimeout(()=>{
      this.spinner.hide()
    },10000);
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[100,150,200],
      "order": [[ 2, 'desc' ]],
      searching: false
    };

    this.clubService.getClub()
      .subscribe((res: any) => {
        this.listClub = res.data;
        this.dtTigger.next();
        this.spinner.hide()
      })
  }

  /**
   * loadListe
   */
  public load() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.clubService.getClub()
        .subscribe((res: any) => {
          this.listClub = res.data;
          this.dtTigger.next();
          this.spinner.hide()
        })
    })
  }


  /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.clubId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }



  /**
   * delete
   */
  public async delete() {
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},12000);
    setTimeout(()=>{
      this.spinner.hide()
    },10000);
    const token =  await localStorage.getItem('userToken');
    this.clubService.delete(token, this.clubId)
      .subscribe((res: any) => {
        this.load();
        this.resetField();
        this.spinner.hide();
      })
  }

  public resetField() {
    this.ngbModal.dismissAll();
     this.clubId = '';
  }

  /**
   * emitDate
   */
   public emitNavigateToModifyClub(userId) {
    localStorage.setItem('modificationId', userId);
    this.navigateToModifyClub.emit({page:"modifierclub", titlePage:"Modifier club"})
  }

  public filterByIndex(index1, index2) {
     if(parseInt(index2) < parseInt(index1)){
       alert("Le deuxième champ ne pourrait être pas inférieur au premier champ.");
       return;
     }
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},12000);
    if(index1 !== "" && index2 !== ""){
       this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
         dtInstance.destroy();
         this.clubService.getClubbyIndex(index1, index2)
           .subscribe((res: any) => {
             this.listClub = res.data;
             this.dtTigger.next();
             this.spinner.hide()
           })
       })
     }
  }


  public rechercherclub(value) {
    // this.spinner.show();
    // setTimeout(()=>{this.spinner.hide()},12000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.clubService.rechercherclub({search: value.target.value})
        .subscribe((res: any) => {
          this.listClub = res.data;
          // this.spinner.hide()
        })
    })
  }


}

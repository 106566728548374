<div class="card-body">

  <app-date-filter (itemConcat)="filterByDate($event)" (itemListAll)="loadListejoueurs()"></app-date-filter>

  <div class="row" style="margin-top: 15px;">
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
      <thead>
      <tr>
        <th><div>Active</div>/desactive</th>
        <th>Nom d'utilisateur</th>
        <th>Nom et Prénoms</th>
        <th>Raison du signalement</th>
        <th>Téléphone</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of listJoueurs" class="pointer-cl">
        <td>
          <input style="margin-left: 35%" type="checkbox" (change)="activedesactive(item?.id, $event)" [checked]="item.enabled ==='ACTIVE'" >
        </td>
        <td >{{item.username !== null ? item.username : ''}}</td>
        <td >{{item.last_name !== null ? item.last_name : ''}} {{item.first_name !== null ? item.first_name : ''}}</td>
        <td >{{item.flagcomment ? item.flagcomment : ''}}</td>
        <td >{{item.phone ? item.phone : ''}}</td>
        <td style="display: flex; flex-direction: row">
          <button style="margin-right: 5px; display: flex; flex-direction: column;" class="btn btn-sm btn-secondary"  (click)="emitNavigateToShowImage(item.id)">
            <i class="fas fa-file-video"></i>
            <span *ngIf="item.nbphoto != 0" style="border-radius: 15px; margin-top: 7px;
                     padding-top:2px;padding-bottom: 2px;padding-left: 4px; padding-right: 4px;
                      background-color: #478edb; color:'white'; font-size: 10px;">{{item.nbphoto}}</span>
          </button>
          <button style="margin-right: 5px;  display: flex; flex-direction: column;" class="btn btn-sm btn-secondary"  (click)="emitNavigateToShowVideo(item.id)">
            <i class="fas fa-video"></i>
            <span *ngIf="item.nbvideo != 0" style="border-radius: 15px; margin-top: 7px;
                     padding-top:2px;padding-bottom: 2px;padding-left: 4px; padding-right: 4px;
                      background-color: #478edb; color:'white'; font-size: 10px;">{{item.nbvideo}}</span>
          </button>
          <button style="margin-right: 5px;" class="btn btn-sm btn-success"  (click)="emitNavigateToModifyJoueur(item.id)">
            <i class="fas fa-edit"></i>
          </button>
          <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
            <i class="fas fa-trash"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


<ng-template #confirmDelete>
  <div class="card-body">
    Etes-vous sûr de vouloir supprimer?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()">OUI</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
  </div>
</ng-template>

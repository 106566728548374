import { Component, OnInit, OnChanges, Output, EventEmitter,} from '@angular/core';
import { Subject } from "rxjs";
import { DatatableLanguage } from "../../constant/french-datatable";
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { EntraineurService } from 'src/app/services/entraineur.service';
import {ClubService } from 'src/app/services/club.service';
import { NgxSpinnerService } from "ngx-spinner";
import {JoueurService} from "../../services/joueur.service";
import {ENV_PROD} from "../../../environments/config-env";

import set = Reflect.set;
// import { Socket } from 'ngx-socket-io';
import * as io  from "socket.io-client";
const SocketEndpoint = ENV_PROD.urlApi1;


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit , OnChanges{


  public page : any;
  public title : any;
  public adminName : any;
  public adminType : any;

  public userWaiting: any;

  public email: any;
  public countEntraineurs: any = 0;
  public countJoueurs: any = 0;
  public countclubs: any  = 0;
  public countclubetmembre: any = 0;
  public joueursignale: any = 0;
  private socket;


  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  constructor(
    private router: Router,
    private locationStrategy: LocationStrategy,
     private entraineurService: EntraineurService,
     private joueurService: JoueurService,
    private spinner: NgxSpinnerService,
    private clubService: ClubService,
    // private socket: Socket,//ngx-socket
  ) {
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, window.location.href)
    });
    this.countJoueurs = this.countEntraineurs = this.countclubs = 0
  }

  ngOnInit(): void {
    this.socket = io(SocketEndpoint);

    this.socket.on("notificationbadge_depuisserver", payload => {
      this.joueursignale = payload;
    });

    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},12000);
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu : [5, 10, 25],
      processing: true,
      language: DatatableLanguage.datatableFrench
    };
    this.adminName = localStorage.getItem('adminName');
    this.adminType = localStorage.getItem('adminType');


    if (localStorage.getItem('page') == 'home') {
      this.page = 'home';
      this.title = 'Tableau de bord'
    } else {
      this.page = localStorage.getItem('page');
      switch (this.page) {
        case 'ajouterjoueur':
          this.title = 'Ajouter joueur';
          break;
        case 'listerjoueur':
          this.title = 'Joueurs';
          break;
        case 'afficherjoueur':
          this.title = 'Afficher joueurs';
          break;
        case 'ajouterentraineur':
          this.title = 'Ajouter un entraineur';
          break;
        case 'listerentraineur':
          this.title = 'Entraineurs';
          break;
        case 'ajouterclub':
          this.title = 'Ajouter un club';
          break;
        case 'ajouterclubetmembre':
          this.title = 'Ajouter club et membre';
          break;
        case 'listerclub':
          this.title = 'Clubs';
          break;
        case 'listerclubetmembre':
          this.title = 'Lister club et membre';
          break;
        case 'position':
          this.title = 'Position';
          break;
        case 'cgu':
          this.title = 'CGU';
          break;
        case 'niveau':
          this.title = 'Carrière niveau';
          break;
        case 'diplome':
          this.title = 'Diplômes';
          break;
        case 'categorie':
          this.title = 'Catégories';
          break;
        case 'schemajeux':
          this.title = 'Schéma de jeux';
          break;
        case 'showimagejoueur':
          this.title = 'Gallerie photos';
          break;
        case 'showvideojoueur':
          this.title = 'Gallerie videos';
          break;
        case 'signalerjoueur':
          this.title = 'Signalement joueur';
          break;
        default:
          break
      }
    }

    this.email = localStorage.getItem('adminEmail');
    this.initializeDashboard();
    this.loadUserWaiting()
  }

  ngOnChanges(): void {
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},12000);
    this.loadUserWaiting()
  }

  public loadUserWaiting() {
    // this.service.getData('user/need-cerfiticate')
    //   .subscribe((res: any) => {
    //     this.userWaiting = res.data.length
    //   })
  }

  public initializeDashboard() {

    this.joueurService.getJoueurssignale()
      .subscribe((res: any) => {
        this.joueursignale = res.data.length;
      })

    this.entraineurService.getEntraineurs()
      .subscribe((res: any) => {
        this.countEntraineurs = res.data.length
      });


    this.joueurService.getJoueurs()
      .subscribe((res: any) => {
        this.countJoueurs = res.data.length
      });


    this.clubService.getClubaaaaaaaall()
      .subscribe((res: any) => {
        this.countclubs = res.data.length
      });

    this.clubService.getClubetmembre()
      .subscribe((res: any) => {
        this.countclubetmembre = res.data.length
      });


    this.spinner.hide()
  }



  public toPage(page, titlePage) {
    this.page = page;
    this.title = titlePage;
    localStorage.setItem('page', this.page);
    if (page == 'home') {
      this.initializeDashboard()
    }
  }




  public sign_out(){
    localStorage.clear();
    this.router.navigateByUrl('');
  }


  public funcNavigateToModifyJoueur(event) {
    this.toPage(event.page, event.titlePage);
  }

  public funcNavigateToshowimagejoueur(event) {
    this.toPage(event.page, event.titlePage);
  }
  public funcNavigateToshowvideojoueur(event) {
    this.toPage(event.page, event.titlePage);
  }


  public funcNavigateToModifyEntraineur(event) {
    this.toPage(event.page, event.titlePage);
  }

  public funcNavigateToPosition(event) {
    this.toPage(event.page, event.titlePage);
  }

  public funcNavigateTo(event) {
    this.toPage(event.page, event.titlePage);
  }
  public funcNavigateToDiplome(event) {
    this.toPage(event.page, event.titlePage);
  }

  public funcNavigateToModifyClub(event) {
    this.toPage(event.page, event.titlePage);
  }
  public funcNavigateToModifyClubetmembre(event) {
    this.toPage(event.page, event.titlePage);
  }





}

import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  OnChanges,
  OnDestroy,
  ElementRef,
  LOCALE_ID,
  Inject,
  Output
} from '@angular/core';
import { JoueurService } from 'src/app/services/joueur.service';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import {NgbAlert, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import {NgxSpinnerService} from "ngx-spinner";
import {DatePipe, formatDate} from "@angular/common";
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import * as moment from 'moment';
import {ENV_PROD} from "../../../environments/config-env";
import { Router } from '@angular/router';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import {ClubService} from "../../services/club.service";
import {AdminService} from "../../services/admin.service";


@Component({
  selector: 'app-modifierjoueur',
  templateUrl: './modifierjoueur.component.html',
  styleUrls: ['./modifierjoueur.component.css']
})

export class modifierjoueurComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;
  @Output() navigateToPosition: EventEmitter<any> = new EventEmitter();

  fileUploadForm: FormGroup;
  fileInputLabel: string;
  public ismodifierpasswordvar: boolean = false;
  public avatar: any = "";
  //-----------------------
  public listePosition: any = [];
  public userId: string = "";
  public hommefemme: string = "";
  public first_name: string = "";
  public last_name: string = "";
  public username: string = "";
  public datenaissance: string = "";
  public selectedposition: any = null;
  public selectedpositionname: any = null;
  public taille: string = "";
  public poids: string = "";
  public droitegauchelesdeux: string = "";
  public telephone: string = "";
  public email: string = "";
  public nationalite1: string = "";
  public nationalite2: string = "";
  public region: string = "";
  public departement: string = "";
  public disponibilite: string = "";
  public password: string = "";
  public confirmpassword: string = "";
  public isnouveauclub: any = null;
  public club: string = null;
  public newClub: string = "";
  public emailClub: string = "";
  public numeroAffiliation: string = "";
  public listeClub: any = [];
  public selectedclubname: string = "";
  public emailClubsave: string = "";
  public numeroAffiliationsave: string = "";
  public index1: any = 0;
  public index2: any = 150;

  constructor(
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private joueurService: JoueurService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private router: Router,
    private clubService: ClubService,
    private adminService: AdminService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},10000);
    this.joueurService.getPosition()
      .subscribe(async (res: any) => {
        if (res.error === "Unauthorized" && res.message === 'Authentication failed (token) decoded sub.') {
          localStorage.setItem('userToken', "");
          this.router.navigateByUrl('');
        } else {
          this.listePosition = res.data;
          if(this.listePosition.length === 0){
            this.spinner.hide();
            if (window.confirm("Aucun position à choisir pour le joueur, veuillez en ajouter dans la menu configuration."))
            { this.navigateToPosition.emit({page:"position", titlePage:"Position"}) }
            else
            {
              // They clicked no
            }
          }
          this.spinner.hide()
        }
      });

    this.clubService.getListeclub()
      .subscribe(async (res: any) => {
        this.listeClub = res.data;
        this.spinner.hide();
      });

    this.userId = localStorage.getItem('modificationId');
    let token = localStorage.getItem('userToken');
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},10000);
    this.joueurService.getJoueurById(token, this.userId)
      .subscribe(async (res: any) => {
        if(res.data.urlavatar.length !== 8 && res.data.avatar != '' && res.data.avatar != null){
          this.avatar = res.data.urlavatar;
        }
        this.hommefemme = res.data.sexe;
        this.first_name = res.data.first_name;
        this.last_name = res.data.last_name;
        this.username = res.data.username;
        this.datenaissance = this.dateFormater(res.data.dateNaissance);
        if(res.data.placebyuser !== null && res.data.placebyuser.length>0) {
          const plcbyuser = res.data.placebyuser.map(e => e.id).join(",");
          this.selectedposition = plcbyuser;
          const plcbyusername = res.data.placebyuser.map(e => e.name).join(", ");
          this.selectedpositionname = plcbyusername;
        }
        this.taille = res.data.taille;
        this.poids = res.data.poids;
        this.droitegauchelesdeux = res.data.piedfort;
        this.telephone = res.data.phone;
        this.email = res.data.email;
        this.nationalite1 = res.data.nationalite1;
        this.nationalite2 = res.data.nationalite2;
        this.region = res.data.region;
        this.departement = res.data.departement;
        this.disponibilite = res.data.disponibilite;
        if (res.data && res.data.clubactuel && res.data.clubactuel !== null && res.data.clubactuel.length>0 ) {
          this.club = res.data.clubactuel[0];
          this.selectedclubname = res.data.clubactuel[0].name;
          this.emailClub = res.data.clubactuel[0].email;
          this.emailClubsave = res.data.clubactuel[0].email;
          this.numeroAffiliation = res.data.clubactuel[0].numeroAffiliation;
          this.numeroAffiliationsave = res.data.clubactuel[0].numeroAffiliation;
        }
      });

    this.fileUploadForm = this.formBuilder.group({
      uploadedImage: [''],
    });
  }


  ngOnDestroy(): void {
  }


  ngOnChanges(): void {
    this.load()
  }

  load(){
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},10000);
    this.joueurService.getPosition()
      .subscribe(async (res: any) => {
        if (res.error === "Unauthorized" && res.message === 'Authentication failed (token) decoded sub.') {
          localStorage.setItem('userToken', "");
          this.router.navigateByUrl('');
        } else {
          this.listePosition = res.data;
          if(this.listePosition.length === 0){
            this.spinner.hide();
            if (window.confirm("Aucun position à choisir pour le joueur, veuillez en ajouter dans la menu configuration."))
            {
              this.navigateToPosition.emit({page:"position", titlePage:"Position"})
            }
            else
            {
              // They clicked no
            }
          }
          this.spinner.hide()
        }
      });

    this.clubService.getListeclub()
      .subscribe(async (res: any) => {
        this.listeClub = res.data;
        this.spinner.hide();
      });

    this.userId = localStorage.getItem('modificationId');
    let token = localStorage.getItem('userToken');
    let adminId = localStorage.getItem('adminId');
    this.adminService.gettokenbyid(adminId)
      .subscribe(async (res: any) => {
        localStorage.setItem('userToken', res.data);
        token = res.data;
      });
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},10000);
    this.joueurService.getJoueurById(token, this.userId)
      .subscribe(async (res: any) => {
        this.avatar = res.data.urlavatar;
        this.hommefemme = res.data.sexe;
        this.first_name = res.data.first_name;
        this.last_name = res.data.last_name;
        this.username = res.data.username;
        this.datenaissance = this.dateFormater(res.data.dateNaissance);
        const plcbyuser = res.data.placebyuser.map(e => e.id).join(",");
        this.selectedposition = plcbyuser;
        const plcbyusername = res.data.placebyuser.map(e => e.name).join(",");
        this.selectedpositionname = plcbyusername;
        this.taille = res.data.taille;
        this.poids = res.data.poids;
        this.droitegauchelesdeux = res.data.piedfort;
        this.telephone = res.data.phone;
        this.email = res.data.email;
        this.nationalite1 = res.data.nationalite1;
        this.nationalite2 = res.data.nationalite2;
        this.region = res.data.region;
        this.departement = res.data.departement;
        this.disponibilite = res.data.disponibilite;
        if (res.data && res.data.clubactuel && res.data.clubactuel !== null && res.data.clubactuel.length>0 ) {
          this.club = res.data.clubactuel[0];
          this.selectedclubname = res.data.clubactuel[0].name;
          this.emailClub = res.data.clubactuel[0].email;
          this.emailClubsave = res.data.clubactuel[0].email;
          this.numeroAffiliation = res.data.clubactuel[0].numeroAffiliation;
          this.numeroAffiliationsave = res.data.clubactuel[0].numeroAffiliation;
        }
      });

    this.fileUploadForm = this.formBuilder.group({
      uploadedImage: [''],
    });
  }

  public dateFormater(date) {
    const date_ = new Date(date);
    return date_.getFullYear() + '-' + (date_.getMonth() + 1).toString().padStart(2, '0') + '-' + date_.getDate().toString().padStart(2, '0')
  }


  public async changeClub(item: any) {
    this.club = item;
  }


  public async onModifyChoiceClub(event) {
    if(this.isnouveauclub == 'true'){
      this.numeroAffiliation = "";
      this.emailClub = "";
    }else{
      this.numeroAffiliation = this.numeroAffiliationsave;
      this.emailClub = this.emailClubsave;
    }
  }


  onFileSelect(event) {
    const file = event.target.files[0];
    this.fileInputLabel = file.name;
    this.fileUploadForm.get('uploadedImage').setValue(file);
  }

  public async changeClient(item: any) {
    this.selectedposition = item;
  }

  ismodifierpassword(event){
  this.ismodifierpasswordvar =  event.target.checked;
  }

  async modifierjoueur(){

    if(this.hommefemme === ""){
      alert("Veuillez choisir le genre du joueur.");
      return;
    }

    if(this.first_name === ""){
      alert("Le nom du joueur ne pourrait pas être vide.");
      return;
    }
    if(this.last_name === ""){
      alert("Le prénom du joueur ne pourrait pas être vide.");
      return;
    }
    if(this.username === ""){
      alert("Le nom d'utilisateur du joueur ne pourrait pas être vide.");
      return;
    }
    if(this.datenaissance === ""){
      alert("La date de naissance du joueur ne pourrait pas être vide."); //2021-07-12
      return;
    }
    if(this.selectedposition === null){
      alert("Veuillez choisir au moins une position.");
      return;
    }
    if(this.taille === ""){
      alert("La taille du joueur ne pourrait pas être vide.");
      return;
    }
    if(this.poids === ""){
      alert("Le poids du joueur ne pourrait pas être vide.");
      return;
    }
    if(this.droitegauchelesdeux === ""){
      alert("Veuillez choisir le pied fort du joueur.");
      return;
    }
    if(this.telephone === ""){
      alert("Le téléphone du joueur ne pourrait pas être vide.");
      return;
    }
    if(this.telephone !== ""  && !this.adminService.validatePhoneNumber(this.telephone.trim())){
      alert("Veuillez insérer un numéro de téléphone valide.");
      return;
    }
    if(this.email === ""){
      alert("L'email du joueur ne pourrait pas être vide.");
      return;
    }
    if(this.email !== "" && !this.adminService.validateEMail(this.email)){
      alert("Veuillez insérer un adresse e-mail valide.");
      return;
    }
    if(this.nationalite1 === ""){
      alert("La nationalité 1 du joueur ne pourrait pas être vide.");
      return;
    }
    if(this.region === ""){
      alert("La région où habite le joueur ne pourrait pas être vide.");
      return;
    }
    if(this.departement === ""){
      alert("Le département où habite le joueur ne pourrais pas être vide.");
      return;
    }
    if(this.disponibilite === ""){
      alert("Veuillez choisir la disponibilité du joueur.");
      return;
    }
    if(this.disponibilite !== "LIBRE"){
      if(this.isnouveauclub == null && this.club === null && this.newClub === ""){
        alert("Veuillez choisir le club pour le membre.");
        return;
      }
      if(this.isnouveauclub == 'true' &&  this.newClub === ""){
        alert("Veuillez inserer le nom du nouveau club.");
        return;
      }
      if( this.isnouveauclub === 'false' && this.club === null){
        alert("Veuillez choisir le club.");
        return;
      }
      if(this.isnouveauclub == 'true' && this.emailClub === ""){
        alert("Veuillez insérer l'email du club.");
        return;
      }
      if(this.emailClub !== "" && !this.adminService.validateEMail(this.emailClub)){
        alert("Veuillez insérer un adresse e-mail valide.");
        return;
      }
      if(this.isnouveauclub == 'true' && this.numeroAffiliation === ""){
        alert("Veuillez insérer le numéro d'affiliation du club.");
        return;
      }
    }

    if(this.ismodifierpasswordvar){
      if(this.password === ""){
        alert("Veuillez inserer le nouveau mot de passe pour le joueur.");
        return;
      }
      if(this.password !== this.confirmpassword){
        alert("Le mot de passe et la confirmation du mot de passe n'est pas identique.");
        return;
      }
    }

    if(this.password === this.confirmpassword && this.password !== "" && this.confirmpassword !== "" && !this.ismodifierpasswordvar){
      alert("La modification du mot de passe n'est pas pris en compte tant que vous ne cliquez pas sur l'activation du mot de passe.");
      return;
    }


    let parent = {};
    const data = {
      sexe:this.hommefemme,
      first_name:this.first_name.trim(),
      last_name:this.last_name.trim(),
      username:this.username.trim(),
      dateNaissance: this.datenaissance,
      taille:this.taille,
      poids:this.poids,
      piedfort : this.droitegauchelesdeux,
      phone: this.telephone.trim(),
      email:this.email.trim().toLowerCase(),
      nationalite1: this.nationalite1.trim(),
      region:this.region.trim(),
      departement:this.departement.trim(),
      role:"JOUEUR",
      disponibilite:this.disponibilite,
    };

    if(this.isnouveauclub === 'true'){
      parent["club"] ={ id:0 };
      parent["newClub"] = this.newClub;
      parent["emailClub"] = this.emailClub;
      parent["numeroAffiliation"] = this.numeroAffiliation;
    }else {
      parent["club"] = this.club;
    }

    parent['placebyuser'] = [this.selectedposition];
    if( this.nationalite2 !== ""){
      data['nationalite2'] = this.nationalite2;
    }

    if(this.ismodifierpasswordvar) {
      data['password'] = this.password;
    }

    this.spinner.show();
    setTimeout(()=>{
      this.spinner.hide();
    },12000);
    const formData = new FormData();
    let headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin' : '*',
    });
    let options = { headers: headers };
    formData.append('data', this.fileUploadForm.get('uploadedImage').value);
    this.uploadFileInput.nativeElement.value = '';
    if (this.fileUploadForm.get('uploadedImage').value){
      this.httpClient.post<any>(ENV_PROD.urlApi + 'upload-image',
        formData
        // , options
      ).subscribe(
        (response) => {
          if (response.status === 200) {
            this.avatar = response.urlfile;
            data['avatar'] = response.nameFile;
            parent['user'] = data;
            parent['userId'] = this.userId;
            this.fileInputLabel = undefined;
            this.httpClient.post<any>(ENV_PROD.urlApi + 'users/modificationjoueur', parent).subscribe(
              (response1) => {
                this.spinner.hide();
                if (response1.status === 200){
                  alert(ENV_PROD.nom_application + " \n " + response1.message);
                  this.load()
                }else if (response1.status === 404 || response1.status === 300 || response1.status === 301){
                  alert(ENV_PROD.nom_application + " \n " +  response1.message);
                  this.load()
                }})
          }else{
            alert(ENV_PROD.nom_application + " \n " + "L'image n'a pas pu être envoyée.");
          }
        })
    }else{
      parent['user'] = data;
      parent['userId'] = this.userId;
      this.fileInputLabel = undefined;
      this.httpClient.post<any>(ENV_PROD.urlApi + 'users/modificationjoueur', parent).subscribe(
        (response1) => {
          this.spinner.hide();
          if (response1.status === 200){
            alert(ENV_PROD.nom_application + " \n " + response1.message);
            this.load()
          }else if (response1.status === 404 || response1.status === 300 || response1.status === 301){
            alert(ENV_PROD.nom_application + " \n " +  response1.message);
            this.load()
          }})
    }
  }


  public filterByIndex(index1, index2) {
    if(parseInt(index2) < parseInt(index1)){
      alert("Le deuxième champ ne pourrait être pas inférieur au premier champ.");
      return;
    }
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},12000);
    if(index1 !== "" && index2 !== ""){
      this.clubService.getClubbyIndex(index1, index2)
        .subscribe((res: any) => {
          this.listeClub = res.data;
          this.spinner.hide()
        })
    }
  }


}

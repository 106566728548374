<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <div style="display:flex; flex:1;background-color:#c8d1d8; align-items: center; justify-content: center;">
        <div style="position:'absolute'; margin-left: 40px; margin-right: 40px;  background-color:rgba(75,79,81,0.69); color: #fdf8ff; align-items: center; top:25px; padding:10px; border-radius: 10px;">
          <div style="display:flex; justify-content: 'center'; font-size: 22px; margin-top: 40px;">C.G.U</div>
          <div style="padding-left: 40px; padding-right: 40px;" *ngFor="let item of listCgu">
            <div style="margin-top: 40px; font-weight: 'bold'; font-size: 19px;">{{item?.name}}</div>
            <div style="margin-top: 15px">{{item?.description}}</div>
          </div>
        </div>
        <img class=""   src="../../../../assets/images/logo.png" style="width: 110%; height: 100%;">
      </div>
    </div>
  </div>
</div>

import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {Club, Entraineur, Joueur} from "../constant/Position"
import { retry, catchError } from 'rxjs/operators'
import { ENV_PROD } from '../../environments/config-env';

@Injectable({
  providedIn: 'root'
})
export class ClubService {

  public apiUrl = ENV_PROD.urlApi;

  constructor(
    private httpClient: HttpClient
  ) { }

  /**
   * getColis
   */


  public getClub(): Observable<Club> {
    return this.httpClient.get<Club>(
      this.apiUrl + 'club/getall',
    ).pipe(retry(1), catchError(this.handleError))
  }



  public rechercherclub(body): Observable<Club> {
    return this.httpClient.post<Club>(
      this.apiUrl + 'rechercherclub',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }

  public getClubbyIndex(index1, index2): Observable<Club> {
    return this.httpClient.get<Club>(
      this.apiUrl + 'club/getClubbyIndex/' + index1 + '/' + index2,
    ).pipe(retry(1), catchError(this.handleError))
  }


  public getClubaaaaaaaall(): Observable<Club> {
    return this.httpClient.get<Club>(
      this.apiUrl + 'club/getClubaaaaaaaall',
    ).pipe(retry(1), catchError(this.handleError))
  }
  public getListeclub(): Observable<Club> {
    return this.httpClient.get<Club>(
      this.apiUrl + 'club/getall',
    ).pipe(retry(1), catchError(this.handleError))
  }
  public getClubetmembre(): Observable<Club> {
    return this.httpClient.get<Club>(
      this.apiUrl + 'getallclubetmembre',
    ).pipe(retry(1), catchError(this.handleError))
  }

  public getClubById(token, id): Observable<Club> {
      return this.httpClient.get<Club>(
        this.apiUrl + 'club/getClubById/' + id ,
        { headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            Authorization: "Bearer " + token,
          })}
      ).pipe(retry(1), catchError(this.handleError))
    }

  public getClubetmembreById(token, id): Observable<Club> {
    return this.httpClient.get<Club>(
      this.apiUrl + 'club/getClubetmembreById/' + id ,
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }

  public delete(token, id): Observable<Joueur> {
    return this.httpClient.get<Joueur>(
      this.apiUrl + 'club/deleteadmin/' + id ,
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }


  public activedesactiveClubetmembre(token, id, trueorfalse): Observable<Entraineur> {
    return this.httpClient.get<Joueur>(
      this.apiUrl + 'clubetmembre/activedesactive/' + id + '/' + trueorfalse,
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }


  public getClubetmembreBetweenTwoDates(token, body): Observable<Club> {
    return this.httpClient.post<Club>(
      this.apiUrl + 'club/clubetmembrebetween2date',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }


  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
}

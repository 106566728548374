import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {Position, Joueur, Media, Niveau } from "../constant/Position"
import { retry, catchError } from 'rxjs/operators'
import { ENV_PROD } from '../../environments/config-env';

@Injectable({
  providedIn: 'root'
})
export class JoueurService {
  public apiUrl = ENV_PROD.urlApi;

  constructor(
    private httpClient: HttpClient
  ) { }


  public ajouterNiveau( body): Observable<Niveau> {
    return this.httpClient.post<Niveau>(
      this.apiUrl + 'niveau/ajouter',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }

  public updateNiveau( body): Observable<Niveau> {
    return this.httpClient.post<Niveau>(
      this.apiUrl + 'niveau/updateadmin',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }


  public deleteNiveau(id): Observable<Niveau> {
    return this.httpClient.get<Niveau>(
      this.apiUrl + 'niveau/delete/' + id,
    ).pipe(retry(1), catchError(this.handleError))
  }

  public getNiveau(): Observable<Niveau> {
    return this.httpClient.get<Niveau>(
      this.apiUrl + 'niveau/getalladmin',
    ).pipe(retry(1), catchError(this.handleError))
  }

  public getCgu(): Observable<Niveau> {
    return this.httpClient.get<Niveau>(
      this.apiUrl + 'cgu/getalladmin',
    ).pipe(retry(1), catchError(this.handleError))
  }

  public ajouterCgu( body): Observable<Niveau> {
    return this.httpClient.post<Niveau>(
      this.apiUrl + 'cgu/add',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          //  Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }

  public updateCgu( body): Observable<Niveau> {
    return this.httpClient.post<Niveau>(
      this.apiUrl + 'cgu/update',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          //  Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }



  public deleteCgu(id): Observable<Position> {
    return this.httpClient.get<Position>(
      this.apiUrl + 'cgu/destroy/' + id,
    ).pipe(retry(1), catchError(this.handleError))
  }

  /**
   * getColis
   */

  public ajouterPosition( body): Observable<Position> {
    return this.httpClient.post<Position>(
      this.apiUrl + 'place/ajouter',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
        //  Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }


  public supprimerPlusieursmedias(data,token): Observable<Media> {
    return this.httpClient.post<Media>(
      this.apiUrl + 'media/supprimerPlusieurs',
      JSON.stringify({arraymedia:data}),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
           Authorization: "Bearer " + token,
        })}
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public updatePosition( body): Observable<Position> {
    return this.httpClient.post<Position>(
      this.apiUrl + 'place/update',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }


  public deletePosition(id): Observable<Position> {
    return this.httpClient.get<Position>(
      this.apiUrl + 'place/delete/' + id,
    ).pipe(retry(1), catchError(this.handleError))
  }

  public getPosition(): Observable<Position> {
    return this.httpClient.get<Position>(
      this.apiUrl + 'place/getall',
    ).pipe(retry(1), catchError(this.handleError))
  }

  public ajouterDiplome( body): Observable<Position> {
    return this.httpClient.post<Position>(
      this.apiUrl + 'diplome/ajouterbo',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          //  Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }


  public updateDiplome( body): Observable<Position> {
    return this.httpClient.post<Position>(
      this.apiUrl + 'diplome/updatebo',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }


  public deleteDiplome(id): Observable<Position> {
    return this.httpClient.get<Position>(
      this.apiUrl + 'diplome/deletebo/' + id,
    ).pipe(retry(1), catchError(this.handleError))
  }

  public getDiplome(): Observable<Position> {
    return this.httpClient.get<Position>(
      this.apiUrl + 'diplome/getallbo',
    ).pipe(retry(1), catchError(this.handleError))
  }


  public ajouterCategorie( body): Observable<Position> {
    return this.httpClient.post<Position>(
      this.apiUrl + 'categorie/ajouterbo',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          //  Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }


  public updateCategorie( body): Observable<Position> {
    return this.httpClient.post<Position>(
      this.apiUrl + 'categorie/updatebo',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }


  public deleteCategorie(id): Observable<Position> {
    return this.httpClient.get<Position>(
      this.apiUrl + 'categorie/deletebo/' + id,
    ).pipe(retry(1), catchError(this.handleError))
  }

  public getCategorie(): Observable<Position> {
    return this.httpClient.get<Position>(
      this.apiUrl + 'categorie/getallbo',
    ).pipe(retry(1), catchError(this.handleError))
  }

  public getJoueurs(): Observable<Joueur> {
    return this.httpClient.get<Joueur>(
      this.apiUrl + 'users/getalljoueursadmin',
    ).pipe(retry(1), catchError(this.handleError))
  }
  public getJoueurssignale(): Observable<Joueur> {
    return this.httpClient.get<Joueur>(
      this.apiUrl + 'users/getalljoueurssignaleadmin',
    ).pipe(retry(1), catchError(this.handleError))
  }

  public getJoueurById(token, id): Observable<Joueur> {
    return this.httpClient.get<Joueur>(
      this.apiUrl + 'users/getUserByIdadmin/'+id,
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }

  public getAllmyphotos(token, id): Observable<Media> {
    return this.httpClient.get<Joueur>(
      this.apiUrl + 'media/getalluserimageadmin/'+id,
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }



  public getAllmyvideos(token, id): Observable<Media> {
    return this.httpClient.get<Joueur>(
      this.apiUrl + 'media/getalluservideoadmin/'+id,
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }


  public getJoueurssignaleBetweenTwoDates(token, body): Observable<Joueur> {
    return this.httpClient.post<Joueur>(
      this.apiUrl + 'users/joueurssignalebetween2date',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }
  public getJoueursBetweenTwoDates(token, body): Observable<Joueur> {
    return this.httpClient.post<Joueur>(
      this.apiUrl + 'users/joueursbetween2date',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }


  public getmediaBetweenTwoDates(token, body): Observable<Media> {
    return this.httpClient.post<Media>(
      this.apiUrl + 'media/between2date',
      JSON.stringify(body),
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }


  public deleteonemedia(token, id): Observable<Joueur> {
    return this.httpClient.get<Joueur>(
      this.apiUrl + 'media/deleteadmin/' + id ,
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }

  public delete(token, id): Observable<Joueur> {
    return this.httpClient.get<Joueur>(
      this.apiUrl + 'users/delete/' + id ,
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }

  public activedesactiveJoueurs(token, id, trueorfalse): Observable<Joueur> {
    return this.httpClient.get<Joueur>(
      this.apiUrl + 'users/activedesactive/' + id + '/' + trueorfalse,
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
         // 'Content-type': 'application/json',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }
  public activedesactiveJoueurssignale(token, id, trueorfalse): Observable<Joueur> {
    return this.httpClient.get<Joueur>(
      this.apiUrl + 'users/activedesactivejoueursignale/' + id + '/' + trueorfalse,
      { headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          // 'Content-type': 'application/json',
          Authorization: "Bearer " + token,
        })}
    ).pipe(retry(1), catchError(this.handleError))
  }
  //-------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------


  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
}

import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  OnChanges,
  OnDestroy,
  ElementRef,
  LOCALE_ID,
  Inject,
  Output
} from '@angular/core';
import { ClubService } from 'src/app/services/club.service';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import {NgbAlert, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import {NgxSpinnerService} from "ngx-spinner";
import {DatePipe, formatDate} from "@angular/common";
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import * as moment from 'moment';
import {ENV_PROD} from "../../../environments/config-env";
import { Router } from '@angular/router';
//-------------------------------------------
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {catchError, retry} from "rxjs/operators";
import {Admin} from "../../constant/Position";
import {AdminService} from "../../services/admin.service";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'app-modifierclubetmembre',
  templateUrl: './modifierclubetmembre.component.html',
  styleUrls: ['./modifierclubetmembre.component.css']
})

export class modifierclubetmembreComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;

  fileUploadForm: FormGroup;
  fileInputLabel: string;
  public dtTigger = new Subject();
  public listeClub: any = [];
  public logo: any = "";
  public isnouveauclub: any = null;
  public first_name: string = "";
  public last_name: string = "";
  public telephone: string = "";
  public adresse: string = "";
  public email: string = "";
  public club: string = null;
  public newClub: string = "";
  public emailClub: string = "";
  public emailClubsave: string = "";
  public numeroAffiliation: string = "";
  public fonctionAuClub: string = "";
  public username: string = "";
  public password: string = "";
  public confirmpassword: string = "";
  public ismodifierpasswordvar: boolean = false;
  public clubId: string = "";
  public selectedclubname: string = "";
  public numeroAffiliationsave: string = "";
  public index1: any = 0;
  public index2: any = 150;


  constructor(
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private clubService: ClubService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private router: Router,
    private adminService: AdminService,
    private alert: AlertService,
    @Inject(LOCALE_ID) private locale: string
  ){}


  ngOnInit(): void {
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},10000);
    this.clubService.getListeclub()
      .subscribe(async (res: any) =>{
        this.listeClub = res.data;
        this.spinner.hide();
    });

    this.clubId = localStorage.getItem('modificationId');
    const token = localStorage.getItem('userToken');
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},10000);
    this.clubService.getClubetmembreById(token, this.clubId)
      .subscribe(async (res: any) => {
      if(res.data && res.data.clubactuel && res.data.clubactuel !== null && res.data.clubactuel.length>0 && res.data.clubactuel[0].urllogo && res.data.clubactuel[0].urllogo.length > 8) {
          this.logo = res.data.clubactuel[0].urllogo;
      }
      this.first_name = res.data.first_name;
      this.last_name = res.data.last_name;
      this.telephone = res.data.phone;
      this.adresse = res.data.adresse;
      this.email = res.data.email;
      if (res.data && res.data.clubactuel && res.data.clubactuel !== null && res.data.clubactuel.length>0 ){
        this.club = res.data.clubactuel[0];
        this.selectedclubname = res.data.clubactuel[0].name;
        this.emailClub = res.data.clubactuel[0].email;
        this.emailClubsave = res.data.clubactuel[0].email;
        this.numeroAffiliation = res.data.clubactuel[0].numeroAffiliation;
        this.numeroAffiliationsave = res.data.clubactuel[0].numeroAffiliation;
      }
     // public newClub: string = "";
      this.fonctionAuClub = res.data.fonctionAuClub;
      this.username = res.data.username;
      // public password: string = "";
      // public confirmpassword: string = "";
      // public ismodifierpasswordvar: boolean = false;
        this.clubId = res.data.id;
      });

    this.fileUploadForm = this.formBuilder.group({
      uploadedImage: [''],
    });
  }


  ngOnDestroy(): void {
    this.dtTigger.unsubscribe()
  }


  ngOnChanges(): void {
    this.load();
  }

  load(){
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},10000);
    this.clubService.getListeclub()
      .subscribe(async (res: any) => {
        this.listeClub = res.data;
        this.spinner.hide();
      });

    this.clubId = localStorage.getItem('modificationId');
    let token = localStorage.getItem('userToken');
    let adminId = localStorage.getItem('adminId');
    this.adminService.gettokenbyid(adminId)
      .subscribe(async (res: any) => {
        console.log("user token", res.data);
        localStorage.setItem('userToken', res.data);
        token = res.data;
      });
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},10000);
    this.clubService.getClubetmembreById(token, this.clubId)
      .subscribe(async (res: any) => {
        if (res.data && res.data.clubactuel && res.data.clubactuel !== null && res.data.clubactuel.length>0 && res.data.clubactuel[0].url_logo && res.data.clubactuel[0].urllogo.length !== 8) {
          this.logo = res.data.clubactuel[0].urllogo;
        }
        this.first_name = res.data.first_name;
        this.last_name = res.data.last_name;
        this.telephone = res.data.phone;
        this.adresse = res.data.adresse;
        this.email = res.data.email;
        if (res.data && res.data.clubactuel && res.data.clubactuel !== null && res.data.clubactuel.length>0 ) {
          this.club = res.data.clubactuel[0];
          this.selectedclubname = res.data.clubactuel[0].name;
          this.emailClub = res.data.clubactuel[0].email;
          this.emailClubsave = res.data.clubactuel[0].email;
          this.numeroAffiliation = res.data.clubactuel[0].numeroAffiliation;
          this.numeroAffiliationsave = res.data.clubactuel[0].numeroAffiliation;
        }
        this.fonctionAuClub = res.data.fonctionAuClub;
        this.username = res.data.username;
        this.clubId = res.data.id;
      });

    this.fileUploadForm = this.formBuilder.group({
      uploadedImage: [''],
    });
    };


  onFileSelect(event) {
    const file = event.target.files[0];
    this.fileInputLabel = file.name;
    this.fileUploadForm.get('uploadedImage').setValue(file);
  }

  public async changeClub(item: any) {
    this.club = item;
  }


  public async onModifyChoiceClub(event) {
    if(this.isnouveauclub == 'true'){
        this.numeroAffiliation = "";
        this.emailClub = "";
    }else{
      this.numeroAffiliation = this.numeroAffiliationsave;
      this.emailClub = this.emailClubsave;
    }
  }



  async modifierclub(){

    if(this.first_name === "" || this.first_name === null){
      this.alert.emptyField("Le nom du membre du club ne pourrait-être pas vide.");
      return;
    }
    if(this.last_name === "" || this.last_name === null){
      this.alert.emptyField("Le prénoms du membre du club ne pourrait-être pas vide.");
      return;
    }
    if(this.telephone === "" || this.telephone === null){
      this.alert.emptyField("Le numéro de téléphone du membre du club ne pourrait-être pas vide.");
      return;
    }
    if(this.telephone !== ""  && !this.adminService.validatePhoneNumber(this.telephone.trim())){
      this.alert.emptyField("Veuillez insérer un numéro de téléphone valide.");
      return;
    }
    if(this.adresse === "" || this.adresse === null){
      this.alert.emptyField("L'adresse du membre du club ne pourrait-être pas vide.");
      return;
    }
    if(this.email === "" || this.email === null){
      this.alert.emptyField("L'email du membre du club ne pourrait-être pas vide.");
      return;
    }
    if(this.email !== "" && !this.adminService.validateEMail(this.email)){
      this.alert.emptyField("Veuillez insérer un adresse e-mail valide.");
      return;
    }
    if(this.selectedclubname === "" && this.isnouveauclub == null && this.club === null && this.newClub === ""){
      this.alert.emptyField("Veuillez choisir le club pour le membre.");
      return;
    }
    if(this.selectedclubname === "" && this.isnouveauclub == 'true' &&  this.newClub === ""){
      this.alert.emptyField("Veuillez inserer le nom du nouveau club.");
      return;
    }
    if(this.selectedclubname === "" &&  this.isnouveauclub === 'false' && this.club === null){
      this.alert.emptyField("Veuillez choisir le club.");
      return;
    }
    if(this.isnouveauclub == 'true' && (this.emailClub === "" || this.emailClub === null)){
      this.alert.emptyField("Veuillez insérer l'email du club.");
      return;
    }
    // if(this.emailClub !== "" && !this.adminService.validateEMail(this.emailClub)){
    //   alert("Veuillez insérer un adresse e-mail valide.");
    //   return;
    // }
    if(this.isnouveauclub == 'true' && (this.numeroAffiliation === "" || this.numeroAffiliation === null)){
      this.alert.emptyField("Veuillez insérer le numéro d'affiliation du club.");
      return;
    }

    if(this.fonctionAuClub === "" || this.fonctionAuClub === null){
      this.alert.emptyField("Veuillez inserer la fonction du membre au sein du club.");
      return;
    }
    if(this.username === "" || this.username === null){
      this.alert.emptyField("Veuillez inserer le nom d'utilisateur du membre du club.");
      return;
    }

    if(this.ismodifierpasswordvar) {
      if (this.password === "" || this.password === null) {
        this.alert.emptyField("Veuillez inserer le mot de passe pour le membre du club.");
        return;
      }
      if (this.password !== this.confirmpassword) {
        this.alert.emptyField("Le mot de passe et la confirmation du mot de passe n'est pas identique.");
        return;
      }
    }

    let parent = {};
    const data = {
      first_name:this.first_name?.trim(),
      last_name:this.last_name?.trim(),
      phone: this.telephone?.trim(),
      adresse:this.adresse?.trim(),
      email:this.email?.trim().toLowerCase(),
      username:this.username?.trim(),
      fonctionAuClub:this.fonctionAuClub?.trim(),
      //------------
      role:"CLUB",
    };
    parent['userId'] = this.clubId;
    if(this.ismodifierpasswordvar) {
      data['password'] = this.password;
    }
    if(this.isnouveauclub === 'true'){
      parent["club"] ={ id:0 };
      parent["newClub"] = this.newClub;
    }else {
      parent["club"] = this.club;
    }
    parent["emailClub"] = this.emailClub?.trim().toLowerCase();
    parent["numeroAffiliation"] = this.numeroAffiliation?.trim();

    this.spinner.show();
    setTimeout(()=>{
      this.spinner.hide();
    },12000);
    const formData = new FormData();
    let headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin' : '*',
    });
    formData.append('data', this.fileUploadForm.get('uploadedImage').value);
    this.uploadFileInput.nativeElement.value = '';
    if (this.fileUploadForm.get('uploadedImage').value){
      this.httpClient.post<any>(ENV_PROD.urlApi + 'upload-image',
        formData
      ).subscribe(
        (response) => {
          if (response.status === 200) {
            this.logo = response.urlfile;
            parent['logo'] = response.nameFile;
            parent['user'] = data;
            this.fileInputLabel = undefined;
            this.httpClient.post<any>(ENV_PROD.urlApi + 'modifiermembreetclub', parent).subscribe(
              (response1) => {
                this.spinner.hide();
                if (response1.status === 200){
                  // this.alert.updateSuccess();
                }else if (response1.status === 404 || response1.status === 300 || response1.status === 301){
                  alert(ENV_PROD.nom_application + " \n " +  response1.message);
                  this.alert.errorMessage(ENV_PROD.nom_application + " \n " +  response1.message);
                }})
          }else{
            this.alert.errorMessage(ENV_PROD.nom_application + " \n " + "L'image n'a pas pu être envoyée.");
          }
        })
    }else{
      parent['user'] = data;
      this.fileInputLabel = undefined;
      this.httpClient.post<any>(ENV_PROD.urlApi + 'modifiermembreetclub', parent).subscribe(
        (response1) => {
          this.spinner.hide();
          if (response1.status === 200){
            this.alert.updateSuccess();
          }else if (response1.status === 404 || response1.status === 300 || response1.status === 301){
            alert(ENV_PROD.nom_application + " \n " +  response1.message);
          }})
    }
  }

  ismodifierpassword(event){
    this.ismodifierpasswordvar =  event.target.checked;
  }

  public filterByIndex(index1, index2) {
    if(parseInt(index2) < parseInt(index1)){
      alert("Le deuxième champ ne pourrait être pas inférieur au premier champ.");
      return;
    }
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},12000);
    if(index1 !== "" && index2 !== ""){
      this.clubService.getClubbyIndex(index1, index2)
        .subscribe((res: any) => {
          this.listeClub = res.data;
          this.spinner.hide()
        })
    }
  }



}

import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  OnChanges,
  OnDestroy,
  ElementRef,
  LOCALE_ID,
  Inject,
  Output
} from '@angular/core';
import { ClubService } from 'src/app/services/club.service';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import {NgbAlert, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import {NgxSpinnerService} from "ngx-spinner";
import {DatePipe, formatDate} from "@angular/common";
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import * as moment from 'moment';
import {ENV_PROD} from "../../../environments/config-env";
import { Router } from '@angular/router';
//-------------------------------------------
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {catchError, retry} from "rxjs/operators";
import {Admin} from "../../constant/Position";
import {AdminService} from "../../services/admin.service";

@Component({
  selector: 'app-modifierclub',
  templateUrl: './modifierclub.component.html',
  styleUrls: ['./modifierclub.component.css']
})

export class modifierclubComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;

  fileUploadForm: FormGroup;
  fileInputLabel: string;
  public dtTigger = new Subject();
  public logo: any = "";
  public newClub: string = "";
  public emailClub: string = "";
  public numeroAffiliation: string = "";
  public pays: string = "";
  public description: string = "";
  public clubId: string = "";

  constructor(
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private clubService: ClubService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private router: Router,
    private adminService: AdminService,
    @Inject(LOCALE_ID) private locale: string

  ) {

  }

  ngOnInit(): void {
    this.clubId = localStorage.getItem('modificationId');
    let token = localStorage.getItem('userToken');
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},10000);
    this.clubService.getClubById(token, this.clubId)
      .subscribe(async (res: any) => {
        if(res.data.logo != '' && res.data.logo != null){
          this.logo = res.data.url_logo;
        }
      this.newClub = res.data.name;
      this.emailClub = res.data.email;
      this.numeroAffiliation = res.data.numeroAffiliation;
      this.pays = res.data.pays;
      this.description = res.data.description;
        this.spinner.hide()
      });

       this.fileUploadForm = this.formBuilder.group({
      uploadedImage: [''],
    });
  }


  ngOnDestroy(): void {
    this.dtTigger.unsubscribe()
  }


  ngOnChanges(): void {
    this.load();
  }

  load(){
    this.clubId = localStorage.getItem('modificationId');
    let token = localStorage.getItem('userToken');
    let adminId = localStorage.getItem('adminId');
    this.adminService.gettokenbyid(adminId)
      .subscribe(async (res: any) => {
        console.log("user token", res.data);
        localStorage.setItem('userToken', res.data);
        token = res.data;
      });
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},10000);
    this.clubService.getClubById(token, this.clubId)
      .subscribe(async (res: any) => {
        if(res.data.logo != '' && res.data.logo != null){
          this.logo = res.data.url_logo;
        }
        this.newClub = res.data.name;
        this.emailClub = res.data.email;
        this.numeroAffiliation = res.data.numeroAffiliation;
        this.pays = res.data.pays;
        this.description = res.data.description;
        this.spinner.hide()
      });
  }


  onFileSelect(event) {
    const file = event.target.files[0];
    this.fileInputLabel = file.name;
    this.fileUploadForm.get('uploadedImage').setValue(file);
  }

  public async changeClient(item: any) {

  }


  async modifierclub(){
    if(  this.newClub === "" || this.emailClub === null){
      alert("Le nom du club ne pourrait-être pas vide.");
      return;
    }

    if(this.emailClub === "" || this.emailClub === null){
      alert("l'email du club ne pourrait-être pas vide.");
      return;
    }
    if(this.emailClub !== "" && !this.adminService.validateEMail(this.emailClub)){
      alert("Veuillez insérer un adresse e-mail valide.");
      return;
    }
    if(this.numeroAffiliation === "" ||this.numeroAffiliation === null){
      alert("Le numéro d'affiliation du club ne pourrait-être pas vide.");
      return;
    }
    if(this.pays === "" || this.pays === null){
      alert("Le pays ne pourrait-être pas vide.");
      return;
    }


    if(this.description !== "" && this.description !== null){
      if(this.description.length > 1000){
        alert("La déscription que vous avez inséré est trop longue.");
        return;
      }}

    const parent = {id:this.clubId};
    const data = {
      name:this.newClub.trim(),
      email:this.emailClub.trim().toLowerCase(),
      numeroAffiliation:this.numeroAffiliation.trim(),
      pays:this.pays.trim(),
    };
    console.log("data",  data);

    if(this.description !== ""){
      data["description"] = this.description;
    }

    this.spinner.show();
    setTimeout(()=>{
      this.spinner.hide();
    },12000);
    const formData = new FormData();
    formData.append('data', this.fileUploadForm.get('uploadedImage').value);
    this.uploadFileInput.nativeElement.value = '';
    if (this.fileUploadForm.get('uploadedImage').value){
      this.httpClient.post<any>(ENV_PROD.urlApi + 'upload-image',
        formData
      ).subscribe(
        (response) => {
          if (response.status === 200) {
            this.logo = response.urlfile;
            data['logo'] = response.nameFile;
            parent['data'] = data;
            this.fileInputLabel = undefined;
            this.httpClient.post<any>(ENV_PROD.urlApi + 'modifierclubadmin', parent).subscribe(
              (response1) => {
                this.spinner.hide();
                if (response1.status === 200){
                  alert(ENV_PROD.nom_application + " \n " + response1.message);
                }else if (response1.status === 404 || response1.status === 300 || response1.status === 301){
                  alert(ENV_PROD.nom_application + " \n " +  response1.message);
                }})
          }else{
            alert(ENV_PROD.nom_application + " \n " + "L'image n'a pas pu être envoyée.");
          }
        })
    }else{
      this.fileInputLabel = undefined;
      parent['data'] = data;
      this.httpClient.post<any>(ENV_PROD.urlApi + 'modifierclubadmin', parent).subscribe(
        (response1) => {
          this.spinner.hide();
          if (response1.status === 200){
            alert(ENV_PROD.nom_application + " \n " + response1.message);
          }else if (response1.status === 404 || response1.status === 300 || response1.status === 301){
            alert(ENV_PROD.nom_application + " \n " +  response1.message);
          }})
    }
  }



}

import {Component, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from "ngx-spinner";
import { Time } from "@angular/common";
import set = Reflect.set;
import {ClubService} from "../../services/club.service";
import {AdminService} from "../../services/admin.service";

@Component({
  selector: 'app-listerclubetmembre',
  templateUrl: './Listerclubetmembre.component.html',
  styleUrls: ['./Listerclubetmembre.component.css']
})
export class ListerclubetmembreComponent implements OnInit {

  @ViewChild('actionVoyage') actionVoyage: any;
  @ViewChild('actionShowVoyage') actionShowVoyage: any;
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  @ViewChild('confirmDelete') confirmDelete: any;
  @Output() navigateToModifyClubetmembre: EventEmitter<any> = new EventEmitter();


  public clubetmembreId: any = null;
  public listClubetmembre: any = [];
  public dtOptions: any = {};
  public dtTigger = new Subject();
  public emitData: EventEmitter<any> = new EventEmitter;

  constructor(
    private clubService: ClubService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private adminService: AdminService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(()=>{
      this.spinner.hide()
    },10000);
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100],
      "order": [[ 2, 'desc' ]]
    };

    this.clubService.getClubetmembre()
      .subscribe((res: any) => {
        this.listClubetmembre = res.data;
        console.log("data list listClubetmembre", this.listClubetmembre)
        this.dtTigger.next();
        this.spinner.hide()
      })
  }

  /**
   * loadListe
   */
  public loadListeclubetmembre() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.clubService.getClubetmembre()
        .subscribe((res: any) => {
          this.listClubetmembre = res.data;
          this.dtTigger.next();
          this.spinner.hide()
        })
    })
  }


  public async activedesactive(id, event) {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),6000);
    let token =  await localStorage.getItem('userToken');
    const adminId = localStorage.getItem('adminId');
    this.adminService.gettokenbyid(adminId)
      .subscribe(async (res: any) => {
        console.log("user token", res.data);
        localStorage.setItem('userToken', res.data);
        token = res.data;
      });
    this.clubService.activedesactiveClubetmembre(token, id, event.target.checked)
      .subscribe((res: any) => {
        this.listClubetmembre = res.data;
        this.spinner.hide();
        this.loadListeclubetmembre();
      })
  }


    /**
   * filterByDate
   */
  public filterByDate(item) {
    let dateSplte = item.split(',');
    this.getBy2date(dateSplte[0], dateSplte[1])
  }

  public async getBy2date(start, end) {
    this.spinner.show();
    setTimeout(()=>{
      this.spinner.hide()
    },10000);
    let token =  await localStorage.getItem('userToken');
    const adminId = localStorage.getItem('adminId');
    this.adminService.gettokenbyid(adminId)
      .subscribe(async (res: any) => {
        console.log("user token", res.data);
        localStorage.setItem('userToken', res.data);
        token = res.data;
      });
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      let body = {
        startDate: start,
        endDate: end
      };
      this.clubService.getClubetmembreBetweenTwoDates(token, body)
        .subscribe((res: any) => {
          this.spinner.hide();
          this.listClubetmembre = res.data;
          this.dtTigger.next()
        })
    })
  }


  /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.clubetmembreId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }


  public  parsejson(jsonotparsed: any) {
    return  JSON.parse(jsonotparsed);
  }


  /**
   * delete
   */
  public async delete() {
    this.spinner.show();
    setTimeout(()=>{
      this.spinner.hide()
    },10000);
    let token =  await localStorage.getItem('userToken');
    const adminId = localStorage.getItem('adminId');
    this.adminService.gettokenbyid(adminId)
      .subscribe(async (res: any) => {
        console.log("user token", res.data);
        localStorage.setItem('userToken', res.data);
        token = res.data;
      });
    this.clubService.delete(token, this.clubetmembreId)
      .subscribe((res: any) => {
        this.loadListeclubetmembre();
        this.resetField();
        this.spinner.hide();
      })
  }

  public resetField() {
    this.ngbModal.dismissAll();
     this.clubetmembreId = '';
  }
  /**
   * openModal
   */
  public openModal(modal) {
    this.ngbModal.open(modal)
  }

  // GEstion field data
  public closeModal() {
    this.ngbModal.dismissAll()
  }

  /**
   * emitDate
   */
   public emitNavigateToModifyClubetmembre(userId) {
    localStorage.setItem('modificationId', userId);
    this.navigateToModifyClubetmembre.emit({page:"modifierclubetmembre", titlePage:"Modifier club et membre"})
  }

}
